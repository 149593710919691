import React from 'react';
import { Button, Popconfirm } from 'antd';

import colors from 'config/colors';
import Icon from 'components/Icon';

export const EditAction = ({ handleAction }) => (
  <Button 
    type="link"
    size="middle"
    icon={<Icon name="edit-2" size={16} color={colors.primary} />}
    onClick={handleAction(EditAction.actionType)} />
);

EditAction.actionType = 'edit';


export const DeleteAction = ({ handleAction, resource }) => (
  <Popconfirm
    key="delete"
    trigger="click"
    placement="bottomRight"
    title={`Are sure you want to delete this ${resource}?`}
    okText="Delete"
    okButtonProps={{ type: 'danger' }}
    cancelText="Cancel"
    icon={<Icon name={'alert-circle'} size={16} color={colors.error} />}
    onConfirm={handleAction(DeleteAction.actionType)}
  >
    <Button
      type="link"
      size="middle"
      icon={<Icon name="trash-2" size={16} color={colors.error} />}
    />
  </Popconfirm>
);

DeleteAction.actionType = 'delete';
