import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Space, DatePicker, Table } from 'antd';

import useFilters from 'hooks/useFilters';
import useReports from 'state/reports';
import useLocations from 'state/locations';
import useDoors from 'state/doors';

import MainLayout from 'layouts/Main.layout';
import PageHeader from 'components/PageHeader';
import Grid from 'components/Grid';
// import RadioGroup from 'components/RadioGroup';
import StatsCard from 'components/StatsCard';
import FilterBar from 'components/FilterBar';
import Search from 'components/Search';
import Select from 'components/Select';
import PeriodFilter from 'components/PeriodFilter';

import { toSeconds } from 'helpers/date';
import { mapLocationsToOptions, mapDoorsToOptions } from 'helpers/mapToOptions';
import { DOOR_DETAILS_COLUMNS } from './constants';

// const UNLOAD_LEVELS = [
//   {
//     label: 'Boxes',
//     value: 'boxes',
//   },
//   {
//     label: 'Containers',
//     value: 'containers',
//   }
// ];

const periodToDatePicker = {
  daily: 'date',
  weekly: 'week',
  monthly: 'month',
  yearly: 'year',
};

const useUnloadStatsFilters = useFilters([
  'door_id',
  'name',
]);

const UnloadStats = () => {
  const [isLoading, setIsLoading] = useState(true);
  const reports = useReports();
  const locations = useLocations();
  const doors = useDoors();
  const [stats, setStats] = useState({ data: [], boxesUnloaded: 0, containersUnloaded: 0, avgContainerTime: 0, avgBoxPerMin: 0 });
  const [locationSelect, setLocationSelect] = useState({ options: [], value: null });
  const [doorSelect, setDoorSelect] = useState({ options: [], value: null });
  const [period, setPeriod] = useState('daily');
  const [datePicker, setDatePicker] = useState({ mode: 'date', current: moment(), });
  const filters = useUnloadStatsFilters(stats.data);

  useEffect(() => {
    locations.actions.getAll();
    doors.actions.getAll();
  }, [locations.actions, doors.actions]);

  useEffect(() => {
    const locationOptions = mapLocationsToOptions(locations.state.data);
    setLocationSelect({
      options: locationOptions,
      value: locationOptions.length ? locationOptions[0].value : null,
    });
  }, [locations.state.data]);

  useEffect(() => {
    if (locationSelect.value !== null && doors.state.data && doors.state.data.length) {
      const doorsOptions = mapDoorsToOptions(doors.state.data.filter(d => d.location_id === locationSelect.value));
      doorsOptions.unshift({
        label: 'All Doors',
        value: 'all',
      });
      setDoorSelect({
        options: doorsOptions,
        value: doorsOptions.length ? doorsOptions[0].value : null,
      });
    }
  }, [doors.state.data, locationSelect]);

  useEffect(() => {
    if (doorSelect.value === 'all') {
      filters.setQuery({ door_id: null });
    } else {
      if (typeof doorSelect.value === 'number') {
        filters.setQuery({ door_id: doorSelect.value });
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doorSelect]);

  useEffect(() => {
    if (locationSelect.value !== null && datePicker.current) {
      setIsLoading(true);

      const start = datePicker.current.clone();
      let end = datePicker.current.clone();

      if (period === 'daily') {
        end = moment(start).endOf('day');
      } else if (period === 'weekly') {
        end = moment(start).endOf('week');
      } else if (period === 'monthly') {
        end = moment(start).endOf('month');
      } else if (period === 'yearly') {
        end = moment(start).endOf('year');
      }
      
      const from = toSeconds(new Date(Date.UTC(start.year(), start.month(), start.date())).getTime());
      const to = toSeconds(new Date(Date.UTC(end.year(), end.month(), end.date())).getTime());

      reports.actions.getUnloadStats(locationSelect.value, from, to)
        .then(data => {
          if (!data) return;

          setStats({
            data: data.stats,
            boxesUnloaded: data.summary.tot_boxes,
            containersUnloaded: data.summary.tot_containers,
            avgContainerTime: (data.summary.avg_time_per_container || 0).toFixed(0),
            avgBoxPerMin: (data.summary.boxes_per_min || 0).toFixed(2),
          });

        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports.actions, locationSelect.value, datePicker]);

  useEffect(() => {
    const mode = periodToDatePicker[period];
    let current = null;
    if (period === 'daily') {
      current = moment().startOf('day');
    } else if (period === 'weekly') {
      current = moment().startOf('week');
    } else if (period === 'monthly') {
      current = moment().startOf('month');
    } else if (period === 'yearly') {
      current = moment().startOf('year');
    }

    setDatePicker({ mode, current });
  }, [period]);

  return (
    <MainLayout>
      <PageHeader
        title="Unloads"
        extra={
          <PeriodFilter selectedPeriod={period} onChange={setPeriod} style={{ minWidth: 'calc(64vw - 250px)' }} />
        }
        icon="box"
      />

      <Row style={{ padding: '12px 24px' }}>
        <Space size="large">
          <Select
            options={locationSelect.options}
            value={locationSelect.value}
            onChange={value => setLocationSelect({ ...locationSelect, value })}
            style={{ width: '260px' }}
            placeholder="Select Location"
          />
          <Select
            options={doorSelect.options}
            value={doorSelect.value}
            onChange={value => setDoorSelect({ ...doorSelect, value })}
            style={{ width: '260px' }}
            placeholder="Select Door"
          />
          <DatePicker
            mode={datePicker.mode}
            picker={datePicker.mode}
            value={datePicker.current}
            onChange={date => setDatePicker({ ...datePicker, current: date })}
            style={{ width: '260px' }}
          />
        </Space>
      </Row>

      <Grid gutter={24} columns={4} style={{ padding: '8px 24px 16px' }}>
        <StatsCard
          title="Unloded Boxes"
          value={stats.boxesUnloaded}
          titleAlign={['center', 'bottom']}
        />
        <StatsCard
          title="Unloded Containers"
          value={stats.containersUnloaded}
          titleAlign={['center', 'bottom']}
        />
        <StatsCard
          title="Average time / container"
          value={stats.avgContainerTime}
          titleAlign={['center', 'bottom']}
        />
        <StatsCard
          title="Boxes / Minute"
          value={stats.avgBoxPerMin}
          titleAlign={['center', 'bottom']}
        />
      </Grid>

      {/* <Row style={{ padding: '12px 24px' }}>
        <Col span={24}>
          <Row>
            <RadioGroup
              defaultValue={unloadLevel}
              options={UNLOAD_LEVELS}
              optionType="button"
              buttonStyle="solid"
              onChange={e => setUnloadLevel(e.target.value)}
            />
          </Row>
          <Row>
            Chart
          </Row>
        </Col>
      </Row> */}

      <Row style={{ padding: '24px' }}>
        <Col span={24}>
          <FilterBar
            title="Doors Details"
            titleStyles={{ color: '#232526', fontSize: '30px', lineHeight: '38px' }}
          >
            <FilterBar.Slot align="right">
              <Search placeholder="Search door" value={filters.search} onChange={value => filters.setSearch(value)} />
            </FilterBar.Slot>
          </FilterBar>

          <Table columns={DOOR_DETAILS_COLUMNS} dataSource={filters.data} rowKey="door_id" loading={isLoading} />
        </Col>
      </Row>

    </MainLayout>
  );
};

export default UnloadStats;
