import { useState, useEffect, useMemo } from 'react';

const useModel = (model) => {
  const [, triggerRerender] = useState({});
  const actions = useMemo(() => model.actions, [model.actions]);

  useEffect(() => {
    const newListener = {
      notify: triggerRerender,
    };

    model.listeners.push(newListener);
    newListener.notify(model.state);

    return () => {
      model.listeners = model.listeners.filter(
        listener => listener !== newListener
      );
    };
  }, []); // eslint-disable-line

  return {
    ...model.attributes,
    state: model.state,
    actions,
  };
};

const bindDispatchToActions = (dispatch, actions, model) => {
  const bindedActions = {};
  Object.keys(actions).forEach(key => {
    bindedActions[key] = (...args) => {
      args.push(dispatch);
      args.push(model.state);
      args.push(model.attributes);
      return actions[key].apply(null, args);
    };
  });
  return bindedActions;
};

const createModelDispatch = (model, reducer) => {
  return (event) => {
    if (!event) return;
    console.log('EVENT: ', event);

    const oldState = model.state || {};
    model.state = reducer(oldState, event, model.attributes);
    if (model.state !== oldState) {
      console.log('STORE: ', model.state);

      model.listeners.forEach((listener) => {
        listener.notify(model.state);
      });
    }
  };
};

const createModel = ({ reducer, actions, initialState }, options = {}) => {
  const model = {
    state: initialState || {},
    listeners: [],
    attributes: {
      idField: options.idField || 'id',
      fields: options.fields || [],
    }
  };

  const dispatch = createModelDispatch(model, reducer);
  model.actions = bindDispatchToActions(dispatch, actions, model);

  return useModel.bind(null, model);
};

export default createModel;
