import React, { useState, useCallback, useEffect } from 'react';

import useFilters from 'hooks/useFilters';
import useForm, { FormMode } from 'hooks/useForm';
import useUsers from 'state/users';
import useLocations from 'state/locations';

import AdminLayout from 'layouts/Admin.layout';
import FormModal from 'components/FormModal';
import Table from 'components/Table';
import Button from 'components/Button';
import Search from 'components/Search';
import UserForm from './components/User.form';
import { EditAction, DeleteAction } from './components/TableActions';

import { mapLocationsToOptions } from 'helpers/mapToOptions';
import { USER_TABLE_COLUMNS } from './constants';

const modalTitle = {
  create: 'Add new user',
  edit: 'Update user',
};

const submitButtonText = {
  create: 'Add user',
  edit: 'Update user',
};

const useUsersFilters = useFilters([
  'user_id',
  'full_name',
  'location_name',
  'email',
]);

const UsersPage = () => {
  const users = useUsers();
  const locations = useLocations();
  const [data, setData] = useState([]);
  const [currentItem, setCurrenItem] = useState(null);
  const filters = useUsersFilters(data);
  const [form] = useForm({
    onSubmit: (values) => {
      const save = form.mode === FormMode.create
        ? users.actions.create({ ...values, is_active: 1 })
        : users.actions.update({ ...currentItem, ...values });

      save.then(() => {
        form.reset();
        setCurrenItem(null);
      });
    }
  });

  useEffect(() => {
    // initialize
    users.actions.getAll();
    locations.actions.getAll();
  }, [users.actions, locations.actions]);

  useEffect(() => {
    if (users.state.data && locations.state.data) {
      const locationsData = locations.state.data;
      const usersData = users.state.data.map(user => ({
        ...user,
        location_name: (locationsData.find(l => l.location_id === user.location_id) || {}).location_name,
      }));
      setData(usersData);
    }
  }, [users.state.data, locations.state.data]);

  const onAction = useCallback((actionType, item) => {
    switch (actionType) {
      case EditAction.actionType:
        setCurrenItem(item);
        form.edit(item);
      break

      case DeleteAction.actionType:
        users.actions.remove({
          user_id: item.user_id,
        });
      break;

      default: // make eslint happy :)
    }
  }, [form, users.actions]);
  
  return (  
    <AdminLayout
      title="Users"
      icon="user"
      extra={[
        <Search placeholder="Search users" value={filters.search} onChange={value => filters.setSearch(value)} />,
        <Button type="primary" icon="plus" onClick={form.create}>Add new user</Button>
      ]}
    >
      <FormModal
        title={`${modalTitle[form.mode]}`}
        form={form}
        submitText={`${submitButtonText[form.mode]}`}
        onClose={() => form.reset()}
        width="316px"
      >
        <UserForm form={form} locationOptions={mapLocationsToOptions(locations.state.data)} />
      </FormModal>
      <Table
        loading={users.state.isLoading || locations.state.isLoading}
        columns={USER_TABLE_COLUMNS}
        dataSource={filters.data}
        actions={[
          EditAction,
          DeleteAction,
        ]}
        onAction={onAction}
        actionsProps={{
          resource: 'user',
        }}
        rowKey="user_id"
      />
    </AdminLayout>
  );
};

UsersPage.path = '/users';
export default UsersPage;
