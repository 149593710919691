import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

const Switcher = ({ children, currentKey }) => {
  return React.Children.map(children, child => {
    if (child.type.prototype === View.prototype) {
      if (child && child.props.loading) {
        return <Spin
          size={child.props.loadingSize}
          style={{ width: '100%', padding: '36px' }}
        />
      }

      return React.cloneElement(child, { activeKey: currentKey });
    }
    return child;
  });
};

Switcher.propTypes = {
  currentKey: PropTypes.string,
  children: PropTypes.node,
};

const View = ({ viewKey, activeKey, children }) => {
  return viewKey === activeKey && children;
};

View.propTypes = {
  viewKey: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Switcher.View = View;
export default Switcher;
