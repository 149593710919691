import React from 'react';
import styled from 'styled-components';

import colors from 'config/colors';
import StatsCardBase from 'components/StatsCard';
import Icon from 'components/Icon';

const StatsIconWrapper = styled.div`
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const statsCardStyles = {
  fontWeight: 600,
  fontSize: '38px',
  lineHeight: '46px',
};

const StatsCard = ({ title, value, icon, color }) => (
  <StatsCardBase
    title={title}
    titleAlign={['center', 'bottom']}
    value={value}
    valueStyle={{ color: colors.get(color) || color, ...statsCardStyles }}
    prefix={
      <StatsIconWrapper>
        <Icon name={icon} size={24} />
      </StatsIconWrapper>
    }
  />
);

export default StatsCard;
