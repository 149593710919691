import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Table, Typography } from 'antd';

import useFilters from 'hooks/useFilters';

import useReports from 'state/reports';
import useLocations from 'state/locations';

import MainLayout from 'layouts/Main.layout';
import PageHeader from 'components/PageHeader';
import Grid from 'components/Grid';
import StatsCard from 'components/StatsCard';
import FilterBar from 'components/FilterBar';
import Search from 'components/Search';
import Select from 'components/Select';

import { formatDate } from 'helpers/date';
import { mapLocationsToOptions } from 'helpers/mapToOptions';
import { ACTIVE_INDIVIDUALS_TABLE_COLUMNS } from './constants';

const IndividualsLivePageHeader = styled(PageHeader)`
  & .ant-page-header-content {
    padding-top: unset;
  }
`;

const useIndividualsFilters = useFilters([
  'full_name',
  'name',
]);

const IndividualsActive = () => {
  const [isLoading, setIsLoading] = useState(false);
  const reports = useReports();
  const locations = useLocations();
  const [locationSelect, setLocationSelect] = useState({ options: [], value: null });
  const [stats, setStats] = useState({ data: [], unloading: 0, total: 0 });
  const filters = useIndividualsFilters(stats.data);

  useEffect(() => {
    locations.actions.getAll();
  }, [locations.actions]);

  useEffect(() => {
    const locationOptions = mapLocationsToOptions(locations.state.data);
    setLocationSelect({
      options: locationOptions,
      value: locationOptions.length ? locationOptions[0].value : null,
    });
  }, [locations.state.data]);

  useEffect(() => {
    if (typeof locationSelect.value === 'number') {
      setIsLoading(true);
      reports.actions.getEmployeesLive(locationSelect.value)
        .then(data => {
          if (!data) return;

          const lastUpdate = new Date(data.summary.last_update);

          setStats({
            data: data.doors,
            unloading: data.summary.unloading,
            total: data.summary.tot_employees,
            lastUpdate: lastUpdate ? formatDate(lastUpdate, 'HH:mm') : null,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [locationSelect.value, reports.actions]);

  return (
    <MainLayout>
      <IndividualsLivePageHeader
        title="Active Individuals"
        icon="users"
        subTitle={(
          <Select
            value={locationSelect.value}
            options={locationSelect.options}
            onChange={value => setLocationSelect({ ...locationSelect, value })}
            style={{ width: '200px', marginLeft: '16px' }}
            placeholder="Select Location"
          />
        )}
      >
        <Typography.Text style={{ fontSize: '12px', lineHeight: '20px', color: '#A4B0BA', marginLeft: '45px' }}>
          {stats.lastUpdate ? `Refreshed at: ${stats.lastUpdate}` : ''}
        </Typography.Text>
      </IndividualsLivePageHeader>
      <Grid gutter={24} columns={2} style={{ padding: '8px 24px 16px' }}>
        <StatsCard
          title="Employees seen last 30 min"
          value={stats.total}
          titleAlign={['center', 'bottom']}
        />
        <StatsCard
          title="Unloading Employees last 30 min"
          value={stats.unloading}
          titleAlign={['center', 'bottom']}
        />
      </Grid>

      <Row style={{ padding: '12px 24px', width: '100%' }}>
        <FilterBar
          title="Individuals"
          style={{ width: '100%' }}
        >
          <FilterBar.Slot align="right">
            <Search placeholder="Search individual/door" value={filters.search} onChange={value => filters.setSearch(value)} />
          </FilterBar.Slot>
        </FilterBar>
      </Row>

      <Row style={{ padding: '12px 24px' }}>
        <Col span={24}>
          <Table pagination={{ showSizeChanger: false }} columns={ACTIVE_INDIVIDUALS_TABLE_COLUMNS} dataSource={filters.data} loading={isLoading} rowKey={'process_id'} />
        </Col>
      </Row>
    </MainLayout>
  );
};

export default IndividualsActive;