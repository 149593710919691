import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Row, Col, Space, Dropdown, Button, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import useFilters from 'hooks/useFilters';

import useReports from 'state/reports';
import useLocations from 'state/locations';

import MainLayout from 'layouts/Main.layout';
import PageHeader from 'components/PageHeader';
import ViewSwitcher from 'components/ViewSwitcher';
import Grid from 'components/Grid';
import RadioGroup from 'components/RadioGroup';
import Select from 'components/Select';
import Icon from 'components/Icon';
import Search from 'components/Search';
import FilterBar from 'components/FilterBar';
import ListMode from 'components/ListMode';
import Table from 'components/Table';
import StatsCard from './components/StatsCard';
import DoorCard from './components/DoorCard';
import DoorsFilterBarMenu from './components/DoorsFilterBarMenu';

import { formatDate } from 'helpers/date';
import { mapLocationsToOptions } from 'helpers/mapToOptions';
import { DOORS_TABLE_COLUMNS, STATUS_FILTER_OPTIONS, DOOR_STATE } from './constants';

const DashboardPageHeader = styled(PageHeader)`
  & .ant-page-header-content {
    padding-top: unset;
  }
`;

const useDashboardFilters = useFilters([
  // search fields
  'name',
  'employees.full_name',
  {
    name: 'door_state',
    searchable: false,
    filter: (value, match) => {
      switch (match) {
        case 'all': return true;
        case 'unloading': return value === DOOR_STATE.UNLOADING;
        case 'stopped': return value === DOOR_STATE.STOPPED;
        case 'empty': return value === DOOR_STATE.EMPTY;
        default: return false;
      }
    },
  },
  {
    name: 'unloaded_percent',
    searchable: false,
    filter: (value, match) => {
      if (match === 'all') return true;
      if (typeof value !== 'number') return false;

      const val = parseInt(match, 10);
      if (isNaN(val)) return false;

      const min = val * 0.25, max = (val + 1) * 0.25;
      if (min === 0) {
        return value >= min && value <= max;
      } else {
        return value >= (min + 0.01) && value <= max;
      }
    },
  }
]);

const Dashboard = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const reports = useReports();
  const locations = useLocations();
  const [locationSelect, setLocationSelect] = useState({ options: [], value: null });
  const [stats, setStats] = useState({ data: [], unloading: 0, stopped: 0, empty: 0, employees: 0, lastUpdate: '', });
  const [viewMode, setViewMode] = useState('grid');
  const filters = useDashboardFilters(stats.data, {
    door_state: 'all',
    unloaded_percent: 'all',
  });

  useEffect(() => {
    locations.actions.getAll();
  }, [locations.actions]);

  useEffect(() => {
    const locationOptions = mapLocationsToOptions(locations.state.data);
    setLocationSelect({
      options: locationOptions,
      value: locationOptions.length ? locationOptions[0].value : null,
    });
  }, [locations.state.data]);

  useEffect(() => {
    if (typeof locationSelect.value === 'number') {
      setIsLoading(true);
      reports.actions.getDoorsStats(locationSelect.value)
        .then(data => {
          if (!data) return;

          const lastUpdate = new Date(data.doors.find(d => !!d.last_update).last_update);
          
          setStats({
            data: data.doors,
            lastUpdate: formatDate(lastUpdate, 'HH:mm'),
            ...data.summary,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [locationSelect.value, reports.actions]);

  const onDoorClicked = useCallback((door) => {
    history.push(`/unloads/${door.door_id}`);
  }, [history]);

  return (
    <MainLayout>
      <DashboardPageHeader
        title="Dashboard"
        icon="grid"
        subTitle={(
          <Select
            value={locationSelect.value}
            options={locationSelect.options}
            onChange={value => setLocationSelect({ ...locationSelect, value })}
            style={{ width: '200px', marginLeft: '16px' }}
            placeholder="Select Location"
          />
        )}
      >
        <Typography.Text style={{ fontSize: '12px', lineHeight: '20px', color: '#A4B0BA', marginLeft: '45px' }}>
          {stats.lastUpdate ? `Refreshed at: ${stats.lastUpdate}` : ''}
        </Typography.Text>
      </DashboardPageHeader>
      <Grid gutter={24} columns={4} style={{ padding: '8px 24px 16px' }}>
        <StatsCard
          title="Currently Unloading"
          value={stats.unloading}
          color="success"
          icon="check-circle"
        />
        <StatsCard
          title="Unloading Stopped"
          value={stats.stopped}
          color="error"
          icon="minus-circle"
        />
        <StatsCard
          title="Empty Doors"
          value={stats.empty}
          color="grey.veryDark"
          icon="disc"
        />
        <StatsCard
          title="Employees"
          value={stats.employees}
          color="primary"
          icon="users"
        />
      </Grid>

      <Row justify="center" style={{ padding: '12px 0' }}>
        <RadioGroup
          defaultValue={STATUS_FILTER_OPTIONS[0].value}
          options={STATUS_FILTER_OPTIONS}
          optionType="button"
          buttonStyle="solid"
          onChange={e => {
            filters.clear();
            filters.setQuery({ door_state: e.target.value })
          }}
        />
      </Row>

      <Row style={{ padding: '12px 24px' }} align="center">
        <Col flex="1">
          <FilterBar
            title="All Doors"
            titleStyles={{ fontSize: '20px', lineHeight: '28px' }}
          >
            <FilterBar.Slot align="left">
              <Space size="middle">
                <Search onChange={value => filters.setSearch(value)} value={filters.search} />
                {filters.query.door_state !== STATUS_FILTER_OPTIONS[3].value ? (
                  <Dropdown
                    overlay={() => <DoorsFilterBarMenu value={filters.query.unloaded_percent} onChange={key => filters.setQuery({ unloaded_percent: key })} />}
                    placement="bottomRight"
                  >
                    <Button type="outlined" shape="circle" icon={<Icon name="filter" size={16} />} />
                  </Dropdown>
                ) : null}
              </Space>
            </FilterBar.Slot>
          </FilterBar>
        </Col>
        <Col>
          <ListMode defaultValue={viewMode} onChange={setViewMode} />
        </Col>
      </Row>
      <Row style={{ padding: '12px 24px' }}>
        <Col span={24}>
          <ViewSwitcher currentKey={viewMode}>
            <ViewSwitcher.View viewKey={'grid'} loading={isLoading}>
              <Grid gutter={[24, 24]} columns={2}>
                {
                  filters.data && filters.data.length ? filters.data.map(door => <DoorCard
                    key={door.door_id}
                    door={door}
                    color={door.relative_color}
                    onClick={() => onDoorClicked(door)}
                  />) : null
                }
              </Grid>
            </ViewSwitcher.View>
            <ViewSwitcher.View viewKey={'table'}>
              <Table columns={DOORS_TABLE_COLUMNS} dataSource={filters.data} loading={isLoading} rowKey="door_id" />
            </ViewSwitcher.View>
          </ViewSwitcher>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Dashboard;
