import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Typography, message } from 'antd';

import useAuth from 'state/auth';

import PublicLayout from 'layouts/Public.layout';
import ResetPasswordForm from './components/ResetPasswordForm';

import { LOGIN_PAGE_PATH } from './index';

const ResetPasswordWrapper = styled.div`
  max-width: 364px;
`;

const ResetPasswordInfo = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  display: flex;
  align-items: center;
  text-align: center;
  color: #232526;
  margin: 24px 0;
`;

const ResetPasswordPage = () => {
  const history = useHistory();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!auth.state.currentUser) {
      history.push(LOGIN_PAGE_PATH);
    }
  }, [auth.state, history]);

  useEffect(() => {
    if (error) {
      message.error(error.message, 2);
    }
  }, [error]);

  const onSubmit = useCallback(({ newPassword, resetCode }) => {
    setError(null);
    setIsLoading(true);

    const resetPasswordPromise = auth.state.requiresPasswordReset
      ? auth.actions.resetPassword(newPassword)
      : auth.actions.forgotPasswordComplete(newPassword, resetCode);

    resetPasswordPromise
      .then(() => {
        message.success('Your Password has been changed!', 2, () => {
          history.push('/');
        });
      })
      .catch(err => {
        if (err.code === 'INVALID_RESET_FLOW') {
          history.push(LOGIN_PAGE_PATH);
        } else if (err.code === 'InvalidPasswordException') {
          err.message = err.message.substr(err.message.indexOf(':') + 2);
          setError(err);
        } else {
          setError(err);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

  }, [auth.actions, history, auth.state.requiresPasswordReset]);

  return (
    <PublicLayout>
      <ResetPasswordWrapper>
        <Typography.Title level={2} align="middle" style={{ lineHeight: '38px' }}>
          Reset password
        </Typography.Title>
        <ResetPasswordInfo>
          Please enter
          {!auth.requiresPasswordReset ? ' the reset code your received by email and ' : ' '}
          your new password for your account
        </ResetPasswordInfo>
        <ResetPasswordForm onSubmit={onSubmit} requiresCode={!auth.state.requiresPasswordReset} isLoading={isLoading} />
      </ResetPasswordWrapper>
    </PublicLayout>
  );
};

export default ResetPasswordPage;
