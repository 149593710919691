import React from 'react';
import { Row, Col, Input, Form } from 'antd';

import Select from 'components/Select';

const DoorForm = ({ form, locationOptions }) => (
  <Form
    form={form}
    layout="vertical"
    hideRequiredMark={true}
    onFinish={form.onSubmit}
  >
    <Row gutter={[12]}>
      <Col span={12}>
        <Form.Item
          label="Door Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Door name is required',
            },
          ]}
        >
        <Input placeholder="ex. A01" />
      </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Location"
          name="location_id"
          rules={[
            {
              required: true,
              message: 'Location is required'
            }
          ]}
        >
          <Select options={locationOptions} placeholder="Select Location" />
        </Form.Item>
      </Col>
    </Row>

    <Form.Item
      label="Notes"
      name="notes"
      >
      <Input.TextArea rows={3} placeholder="Notes" />
    </Form.Item>
  </Form>
);

export default DoorForm;