import React, { useCallback } from 'react';
import { Menu} from 'antd';

const DoorsFilterBarMenu = ({ value, onChange }) => {
  const onSelect = useCallback(({ key }) => {
    if (typeof onChange === 'function') {
      onChange(key);
    }
  }, [onChange]);

  return (
    <Menu style={{ width: '150px' }} selectedKeys={[value]} onSelect={onSelect}>
      <Menu.ItemGroup title="Progress">
        <Menu.Item key='all'>All</Menu.Item>
        <Menu.Item key='0'>0-25%</Menu.Item>
        <Menu.Item key='1'>26-50%</Menu.Item>
        <Menu.Item key='2'>51-75%</Menu.Item>
        <Menu.Item key='3'>76-100%</Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );
}

export default DoorsFilterBarMenu;
