import { useState, useEffect, useCallback } from "react";
import Fuse from 'fuse.js';

const defaultFieldFilter = (value, match) => value === match;

const useFilters = (filterFields, data = [], initialQuery = {}) => {
  const [filteredData, setFilteredData] = useState(data);
  const [query, setQuery] = useState(initialQuery);
  const [search, setSearch] = useState(initialQuery._ || '');
  const [filters] = useState(() => {
    const filters = {}, fields = [];
    filterFields.forEach(field => {
      if (typeof field === 'string') {
        field = {
          name: field,
          filter: defaultFieldFilter,
          searchable: true,
        };
      }
      
      filters[field.name] = {
        filter: defaultFieldFilter,
        ...field,
      };
      
      fields.push(field.name);
    });
    
    filters.fields = fields;
    filters.fuse = new Fuse([], {
      tokenize: false,
      isCaseSensitive: false,
      shouldSort: false,
      findAllMatches: true,
      keys: filters.fields.filter(field => filters[field].searchable !== false),
      threshold: 0.1,
    })
    return filters;
  });

  useEffect(() => {
    if (!Array.isArray(data) || !data.length) {
      setFilteredData([]);
      return;
    }

    const fields = filters.fields.filter(key => {
      return query[key] !== undefined;
    });

    let filtered = data;
    if (fields.length) {
      filtered = fields.reduce((items, field) => {
        const match = query[field];
        if (typeof match !== 'boolean' && match !== 0 && !match) {
          return items;
        }

        const filter = filters[field].filter;
        return items.filter(item => filter(item[field], match, item));
      }, filtered);
    }

    if (typeof query._ === 'string' && query._) {
      if (filtered.length) {
        filters.fuse.setCollection(filtered);
        filtered = filters.fuse.search(query._).map(i => i.item);
      }
    }

    setFilteredData(filtered);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, query]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setQuery({ ...query, _: search });
    }, 200);

    return () => {
      clearTimeout(timeout);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const clear = useCallback(() => {
    setQuery(initialQuery);
    setSearch('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setQuery, setSearch]);

  const changeQuery = useCallback((value) => {
    setQuery({ ...query, ...value });
  }, [query]);

  return {
    data: filteredData,
    query,
    search,
    setSearch,
    setQuery: changeQuery,
    clear,
  };
};

/**
 * Create a useFilter hook for the provided filters
 * @param {*} filters 
 * Example:
 * {
 *    status: {
 *      filter: (fieldValue, query, itemData): boolean;
 *    }
 * }
 */
const createFilters = (filterFields = []) => {
  return useFilters.bind(null, filterFields);
};

export default createFilters;
