import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";


import useAuth from 'state/auth';

// Styles
import 'assets/styles/theme.css';

// modules
import Dashboard from './views/Dashboard';
import Unloads from './views/Unloads';
import Individuals from './views/Individuals';
import Admin from './views/Admin';
import Settings from './views/Settings';
import Login from './views/Login';

const {
  pages: {
    DashboardPage
  }
} = Dashboard;

const {
  pages: {
    LoginPage,
    ForgotPasswordPage,
    ResetPasswordPage,
  }
} = Login;

const {
  pages: {
    IndividualsPage,
    IndividualsStatsPage,
    IndividualDetails,
  }
} = Individuals;

const {
  pages: {
    UnloadStatsPage,
    UnloadDoorDetailPage,
    ContainerDetailsPage,
  }
} = Unloads;

const {
  pages: {
    UsersPage,
    LocationsPage,
    DoorsPage,
    EmployeesPage,
  }
} = Admin;

const App = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    auth.actions.loggedInUser()
      .finally(() => setIsLoading(false));
  }, [auth.actions]);


  if (isLoading) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <PublicRoute exact path={LoginPage.path} component={LoginPage} />
        <PublicRoute exact path={ForgotPasswordPage.path} component={ForgotPasswordPage} />
        <PublicRoute exact path={ResetPasswordPage.path} component={ResetPasswordPage} />
        
        <ProtectedRoute exact path={DashboardPage.path} component={DashboardPage} />
        <ProtectedRoute exact path={UnloadStatsPage.path} component={UnloadStatsPage} />
        <ProtectedRoute exact path={UnloadDoorDetailPage.path} component={UnloadDoorDetailPage} />
        <ProtectedRoute exact path={ContainerDetailsPage.path} component={ContainerDetailsPage} />
        <ProtectedRoute exact path={IndividualsPage.path} component={IndividualsPage} />
        <ProtectedRoute exact path={IndividualsStatsPage.path} component={IndividualsStatsPage} />
        <ProtectedRoute exact path={IndividualDetails.path} component={IndividualDetails} />
        <ProtectedRoute exact path={Settings.path} component={Settings} />
        
        <AdminRoute exact path={UsersPage.path} component={UsersPage} />
        <AdminRoute exact path={LocationsPage.path} component={LocationsPage} />
        <AdminRoute exact path={DoorsPage.path} component={DoorsPage} />
        <AdminRoute exact path={EmployeesPage.path} component={EmployeesPage} />

        <Redirect path="/" to={DashboardPage.path} />
      </Switch>
    </Router>
  );
};

const PublicRoute = (props) => {
  const { state: auth } = useAuth();
  const location = useLocation();
  
  if (auth.isAuthenticated) {
    return <Redirect to={DashboardPage.path} />;
  }

  if (auth.requiresPasswordReset && location.pathname !== ResetPasswordPage.path) {
    return <Redirect to={ResetPasswordPage.path} />;
  }
  
  return <Route {...props} />;
}

const ProtectedRoute = (props) => {
  const { state: auth } = useAuth();

  if (!auth.isAuthenticated) {
    return <Redirect to={LoginPage.path} />;
  }

  return <Route {...props} />;
};

const AdminRoute = (props) => {
  const { state: auth } = useAuth();

  if (!auth.isAuthenticated) {
    return <Redirect to={LoginPage.path} />;
  }

  return <Route {...props} />;
};

export default App;
