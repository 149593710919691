import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'antd';

const GridWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Grid = ({ gutter, columns, children, style, colStyle, className }) => {
  const colSpan = Math.floor(24 / columns);
  const items = React.Children.toArray(children);
  const gridItems = items.reduce((gridItems, item, index) => {
    const i = Math.floor(index / columns);
    gridItems[i] = gridItems[i] || [];
    gridItems[i].push(item); 
    return gridItems;
  }, []);

  return (
    <GridWrapper className={className} style={style}>
      {
        gridItems.map((row, i) => 
          <Row key={i} gutter={gutter}>{
            row.map((item, j) =>
              <Col key={j} span={colSpan} style={colStyle}>
                {item}
              </Col>
            )
          }</Row>
        )
      }
    </GridWrapper>
  );
}

Grid.propTypes = {
  gutter: PropTypes.oneOfType([
    PropTypes.number, PropTypes.arrayOf(PropTypes.number)
  ]),
  columns: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Grid.defaultProps = {
  gutter: 24,
}

export default Grid;
