import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Col, Row, Space } from 'antd';

import colors from 'config/colors';

const defaultTitleStyles = {
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '32px',
  color: colors.black
};

const FilterBar = ({ title, titleStyles, style, className, size, children }) => {
  const [slots, setSlots] = useState([]);

  useEffect(() => {
      const slots = React.Children.toArray(children)
        .filter(slot => slot.type.prototype === Slot.prototype);
      setSlots(slots);
  }, [children]);

  const leftSection = slots.filter(slot => slot.props.align === 'left');
  const rightSection = slots.filter(slot => slot.props.align === 'right');

  return (
    <Row justify="space-between" style={style} className={className}>
      <Col>
        <Space size={size}>
          <Typography.Text style={{ ...defaultTitleStyles, ...titleStyles }}>
            {title}
          </Typography.Text>
          {leftSection}
        </Space>
      </Col>
      <Col>
        {rightSection}
      </Col>
    </Row>
  );
};

FilterBar.propTypes = {
  title: PropTypes.string,
  titleStyles: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

const Slot = ({ children }) => children;

Slot.propTypes = {
  children: PropTypes.node,
  align: PropTypes.oneOf(['left', 'right']),
};

Slot.defaultProps = {
  align: 'left',
};

FilterBar.Slot = Slot;
export default FilterBar;
