import createApi from 'services/api';
import {
  IS_LOADING,
  STATS_DOORS,
  STATS_EMPLOYESS,
  STATS_TEAMS,
  STATS_EMPLOYESS_LIVE,
  STATS_UNLOADS,
  STATS_DOOR_DETAILS,
  STATS_EMPLOYEE_DETAILS,
  STATS_CONTAINER_DETAILS,
  STATS_OK,
  STATS_FAILED
} from './constants';

const api = createApi({
  baseUrl: 'reports',
  headers: {
    'Content-Type': 'application/json'
  },
});

export const getDoorsStats = (locationId, dispatch) => createAction({
  action: api.post('location-live', { location_id: locationId }),
  dispatch,
  onSuccess: successHandler(STATS_DOORS),
  onError: errorHandler(STATS_DOORS),
});

export const getEmployeesLive = (locationId, dispatch) => createAction({
  action: api.post('employees-live', { location_id: locationId }),
  dispatch,
  onSuccess: successHandler(STATS_EMPLOYESS_LIVE),
  onError: errorHandler(STATS_EMPLOYESS_LIVE),
});

export const getEmployeesStats = (locationId, from, to, dispatch) => createAction({
  action: api.post('employee-stats', { location_id: locationId, from, to }),
  dispatch,
  onSuccess: successHandler(STATS_EMPLOYESS),
  onError: errorHandler(STATS_EMPLOYESS),
});

export const getTeamsStats = (locationId, from, to, dispatch) => createAction({
  action: api.post('team-stats', { location_id: locationId, from, to }),
  dispatch,
  onSuccess: successHandler(STATS_TEAMS),
  onError: errorHandler(STATS_TEAMS),
});

export const getUnloadStats = (locationId, from, to, dispatch) => createAction({
  action: api.post('unload-stats', { location_id: locationId, from, to }),
  dispatch,
  onSuccess: successHandler(STATS_UNLOADS),
  onError: errorHandler(STATS_UNLOADS),
});

export const getDoorDetails = (doorId, from, to, dispatch) => createAction({
  action: api.post('door-details', { door_id: doorId, from, to }),
  dispatch,
  onSuccess: successHandler(STATS_DOOR_DETAILS),
  onError: errorHandler(STATS_DOOR_DETAILS),
});

export const getEmployeeDetails = (employeeId, from, to, dispatch) => createAction({
  action: api.post('employee-details', { employee_id: employeeId, from, to }),
  dispatch,
  onSuccess: successHandler(STATS_EMPLOYEE_DETAILS),
  onError: errorHandler(STATS_EMPLOYEE_DETAILS),
});

export const getContainerDetails = (containerId, dispatch) => createAction({
  action: api.post('container-details', { container_id: containerId }),
  dispatch,
  onSuccess: successHandler(STATS_CONTAINER_DETAILS),
  onError: errorHandler(STATS_CONTAINER_DETAILS),
});

const createAction = ({ action, dispatch, onSuccess, onError }) => {
  if (typeof action === 'function') {
    action = action();
  }

  dispatch({ type: IS_LOADING, data: true });

  return Promise.resolve(action)
    .then(data => {
      if (typeof onSuccess === 'function') {
        onSuccess(data, dispatch);
      }
      return data;
    })
    .catch(err => {
      if (typeof onError === 'function') {
        onError(err, dispatch);
      }
    })
    .finally(() => {
      dispatch({ type: IS_LOADING, data: false });
    });
};

const successHandler = (statName) => (data, dispatch) => {
  dispatch({ type: STATS_OK, data: { [statName]: data } });
};

const errorHandler = (statName) => (err, dispatch) => {
  dispatch({ type: STATS_FAILED, data: err, statName });
};
