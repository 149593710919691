const colors = {
  primary: '#42B0D5',
  secondary: '',
  success: '#52c41a',
  warning: '#faad14',
  error: '#f5222d',

  heading: 'rgba(0, 0, 0, 0.85)',
  textColor: 'rgba(0, 0, 0, 0.65)',
  borderColor: '#d9d9d9',

  blue: {
    light: '#C7F4FC',   // blue-3 
    normal: '#42B0D5',  // blue-6
    dark: '#104663',    // blue-9
  },

  grey: {
    veryLight: '#EFF2F5', // grey-3
    light: '#DAE0E6',     // grey-5
    normal: '#B9C2C9',    // grey-6
    lightDark: '#828C94', // grey-8
    dark: '#45494D',      // grey-9
    veryDark: '#232526',  // grey-10
  },

  white: '#fff',
  black: '#000',

  get: (colorName = '') => {
    const keys = colorName.split('.');
    if (!keys.length) return undefined;
    let color, root = colors, key;

    for (let i = 0; i < keys.length; i++) {
      if (typeof root !== 'object') break;
      
      key = keys[i];
      if (i === keys.length - 1) {
        color = root[key];
      } else {
        root = root[key];
      }
    }
    return color;
  }
};

export default colors;