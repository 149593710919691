import LoginPage from './Login.page';
import ForgotPasswordPage from './FogotPassword.page';
import ResetPasswordPage from './ResetPassword.page';

export const LOGIN_PAGE_PATH = '/login';
export const RESET_PASSWORD_PATH = '/reset-password';

LoginPage.path = LOGIN_PAGE_PATH;
ForgotPasswordPage.path = '/forgot-password';
ResetPasswordPage.path = RESET_PASSWORD_PATH;

export default {
  pages: {
    LoginPage,
    ForgotPasswordPage,
    ResetPasswordPage,
  },
};
