import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Space } from 'antd';

import MainLayout from './Main.layout';
import PageHeader from 'components/PageHeader';

const ContentWrapper = styled.div`
  padding: 24px;
  padding-top: 0;
`;

const AdminLayout = ({ title, icon, extra, children }) => {
  return (
    <MainLayout>
      <PageHeader
        title={title}
        icon={icon}
        extra={
          <Space size="middle">
            {extra.map((child, i) => React.cloneElement(child, { key: i }))}
          </Space>
        }
      />
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </MainLayout>
  )
};

AdminLayout.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  extra: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
};

export default AdminLayout;
