import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Card, Statistic } from 'antd';

const StyledCard = styled(Card)`
  background-color: #FFFFFF;
  border-radius: 8px;
  border: none;
`;

const verticalAlign = {
  top: 'column',
  bottom: 'column-reverse',
};

const horizontalAlign = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const StyledStatistics = styled(Statistic)`
  display: flex;
  flex-direction: ${({ titleAlign }) => verticalAlign[titleAlign.vertical]};
  justify-content: center;
  align-items: center;

  & .ant-statistic-title {
    align-self: ${({ titleAlign }) => horizontalAlign[titleAlign.horizontal]}
  }
`;


const StatsCard = ({ titleAlign = {}, className, ...props }) => {
  const [ horizontal, vertical] = titleAlign;

  return (
    <StyledCard className={className}>
      <StyledStatistics titleAlign={{ vertical, horizontal }} {...props } />
    </StyledCard>
  );
};

StatsCard.propTypes = {
  titleAlign: PropTypes.arrayOf(PropTypes.string),
};

StatsCard.defaultProps = {
  titleAlign: ['center', 'top'],
}

export default StatsCard;