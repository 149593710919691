import React from 'react';
import { Input, Button, Form } from 'antd';
import Icon from 'components/Icon';

const ResetPasswordForm = ({ onSubmit, requiresCode, isLoading  }) => (
  <Form
    layout="vertical"
    hideRequiredMark={true}
    initialValues={{}}
    onFinish={onSubmit}
    style={{ width: '360px' }}
  > 
    {requiresCode && (<Form.Item
      name="resetCode"
      label="Reset Code"
      rules={[
        {
          required: true,
          message: 'Please enter reset code',
        },
      ]}
    >
      <Input
        prefix={<Icon name="hash" size={16} />}
        size="large"
        placeholder="Enter reset code"
        autoComplete="enter-reset-code"
      />
    </Form.Item>)}

    <Form.Item
      name="newPassword"
      label="New Password"
      rules={[
        {
          required: true,
          message: 'Please enter your new password',
        },
        {
          min: 8,
          message: 'Password must be at least 8 characters in length',
        },
      ]}
    >
      <Input.Password
        prefix={<Icon name="lock" size={16} />}
        type="password"
        size="large"
        placeholder="Enter your new password"
        autoComplete="new-password"
      />
    </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={isLoading} disabled={isLoading} size="large">
        Change Password
      </Button>
    </Form.Item>
  </Form>
);

export default ResetPasswordForm;
