import React from 'react';

import Icon from 'components/Icon';

const menu = {
  dashboard: {
    label: 'Dashboard',
    to: '/dashboard',
    icon: <Icon name='grid' size={16} />,
  },
  unloads: {
    type: 'item',
    label: 'Unloads',
    icon: <Icon name='box' size={16} />,
    to: '/unloads',
  },
  loads: {
    type: 'item',
    label: 'Loads',
    icon: <Icon name='codesandbox' size={16} />,
    to: '/loads',
    disabled: true,
  },
  individuals: {
    type: 'sub',
    label: 'Individuals',
    icon: <Icon name='users' size={16} />,
    items: {
      live: {
        type: 'item',
        label: 'Active',
        to: '/individuals/active',
      },
      stats: {
        type: 'item',
        label: 'Individuals Stats',
        to: '/individuals',
      },
    },
  },
  admin: {
    type: 'section',
    label: 'Administration',
    items: {
      users: {
        type: 'item',
        label: 'Users',
        to: '/users',
        icon: <Icon name='user' size={16} />,
      },
      locations: {
        type: 'item',
        label: 'Locations',
        to: '/locations',
        icon: <Icon name='map-pin' size={16} />,
      },
      doors: {
        type: 'item',
        key: 'doors',
        label: 'Doors',
        to: '/doors',
        icon: <Icon name='airplay' size={16} />,
      },
      servers: {
        type: 'item',
        key: 'servers',
        label: 'Servers',
        to: '/servers',
        icon: <Icon name="cpu" size={16} />,
        disabled: true,
      },
      cameras: {
        type: 'item',
        key: 'cameras',
        label: 'Cameras',
        to: '/cameras',
        icon: <Icon name="video" size={16} />,
        disabled: true,
      },
      employees: {
        type: 'item',
        key: 'employees',
        label: 'Employees',
        to: '/employees',
        icon: <Icon name='users' size={16} />,
        disabled: true,
      }
    },
  },
  account: {
    type: 'section',
    align: 'bottom',
    label: 'Your Account',
    items: {
      settings: {
        type: 'item',
        label: 'Settings',
        to: '/settings',
        icon: <Icon name='settings' size={16} />,
        disabled: true,
      },
      logout: {
        type: 'item',
        label: 'Logout',
        to: '/logout',
        icon: <Icon name='log-out' size={16} />,
      },
    },
  },
};

export default menu;
