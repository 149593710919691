import React from 'react';
import styled from 'styled-components';
import { Card, Typography } from 'antd';

import { formatDate } from 'helpers/date';

const { Text } = Typography;

const StyledCard = styled(Card)`
  background-color: #FFFFFF;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  & .ant-card-body {
    display: flex;
    flex-direction: column;
  }
`;

const StageName = styled(Text)`
  color: ${props => props.color};
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
`;

const Value = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.isEst ? '#A4B0BA' : '#232526'}
`;

const StageCard = ({ stage, color, value, isEst = false, ...rest }) => {
  const formatedValue = value ? formatDate(value * 1000, 'MMM dd, yyyy \'at\' HH:mm') : null;

  return (
    <StyledCard {...rest}>
      <StageName color={color}>{stage}</StageName>
      {value ? <Value isEst={isEst}>{!isEst ? formatedValue : `est. ${formatedValue}`}</Value> : ''}
    </StyledCard>
  );
};

export default StageCard;