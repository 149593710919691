import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Table } from 'antd';

import colors from 'config/colors';
import useReports from 'state/reports';

import MainLayout from 'layouts/Main.layout';
import PageHeader from 'components/PageHeader';
import Icon from 'components/Icon';
import Button from 'components/Button';
import StageCard from './components/StageCard';
import ContainerInfoCard from './components/ContainerInfoCard';
import ContainerStateTag from './components/ContainerStateTag';

import { UNLOADS_PATH } from './index';
import { CONTAINER_EMPLOYEES_TABLE_COLUMNS } from './constants';

const ContainerDetails = () => {
  const reports = useReports();
  const params = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({ container: null, stages: null, employees: [], })

  useEffect(() => {
    const id = parseInt(params.id);
    if (isNaN(id)) {
      history.replace(UNLOADS_PATH);
    }
  }, [params, history]);

  useEffect(() => {
    if (params.id) {
      const containerId = parseInt(params.id, 10);
      if (isNaN(containerId)) return;
      
      setIsLoading(true);

      reports.actions.getContainerDetails(containerId)
        .then(data => {
          if (!data) return;

          const container = data.data ? data.data[0] || null : null;
          let stages = {};

          if (container) {
            stages.docked = { value: container.docked_at || null, isEst: false };

            if (container.started_at) {
              stages.started = { value: container.started_at, isEst: false };
            } else {
              stages.started = { value: container.estimated_start, isEst: true };
            }

            if (container.ended_at) {
              stages.finished = { value: container.ended_at, isEst: false };
            } else {
              stages.finished = { value: container.estimated_end, isEst: true };
            }

            if (container.undocked_at) {
              stages.undocked = { value: container.undocked_at, isEst: false };
            } else {
              stages.undocked=  { value: container.estimated_undock, isEst: true };
            }
          } else {
            stages = null;
          }

          setStats({
            container,
            employees: data.employees ? data.employees : [],
            stages,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [reports.actions, params.id]);

  console.log({
    stats,
  })

  return (
    <MainLayout>
      <PageHeader
        title={stats.container ? `Container ${stats.container.code}` : `Container ${params.id}`}
        backIcon={
          <Button shape="circle" size="large" icon={{ name: "arrow-left", color: colors.grey.lightDark, size: 18 }} />
        }
        tags={
          stats.container? <ContainerStateTag container={stats.container} /> : null
        }
        onBack={() => {
          history.goBack();
        }}
      />

      <Row style={{ padding: '12px 24px', flexWrap: 'nowrap' }} justify="space-between" align="middle">
        <StageCard
          stage='Docked'
          color={colors.primary}
          value={stats.stages && stats.stages.docked.value}
          isEst={stats.stages && stats.stages.docked.isEst}
          style={{ flex: 1 }}
        />
        <Icon name="arrow-right" size={24} color={'#A4B0BA'} />
        <StageCard
          stage="Unload Started"
          color={colors.success}
          value={stats.stages && stats.stages.started.value}
          isEst={stats.stages && stats.stages.started.isEst}
          style={{ flex: 1 }}
        />
        <Icon name="arrow-right" size={24} color={'#A4B0BA'} />
        <StageCard
          stage="Unload Finished"
          color={colors.warning}
          value={stats.stages && stats.stages.finished.value}
          isEst={stats.stages && stats.stages.finished.isEst}
          style={{ flex: 1 }}
        />
        <Icon name="arrow-right" size={24} color={'#A4B0BA'} />
        <StageCard
          stage="Undocked"
          color={colors.error}
          value={stats.stages && stats.stages.undocked.value}
          isEst={stats.stages && stats.stages.undocked.isEst}
          style={{ flex: 1 }}
        />
      </Row>

      <Row style={{ padding: '12px 24px' }}>
        <Col span={24}>
          <ContainerInfoCard container={stats.container} />
        </Col>
      </Row>

      <Row style={{ padding: '12px 24px' }}>
        <Col span={24}>
          <Table pagination={false} columns={CONTAINER_EMPLOYEES_TABLE_COLUMNS} dataSource={stats.employees} loading={isLoading} rowKey="employee_id" />
        </Col>

      </Row>

    </MainLayout>
  );
};

export default ContainerDetails