export const CREATED = 'CREATED';
export const UPDATED = 'UPDATED';
export const DELETED = 'DELETED';
export const FETCHED_ALL = 'FETCHED_ALL';
export const FETCHED = 'FETCHED';
export const LOADING = 'LOADING';
export const DATA_FAILED = 'DATA_FAILED';
export const ITEM_FAILED = 'ITEM_FAILED';
export const TRIGGER_ACTION_BEFORE = 'ACTION_BEFORE';
export const TRIGGER_ACTION_AFTER = 'ACTION_AFTER';

export const initialState = {
  isLoading: false,
  dataError: null,
  itemError: null,
  data: [],
  activeItem: null,
};

export const crudReducer = (state = initialState, action, { idField }) => {
  switch (action.type) {
    case FETCHED_ALL:
      return { ...state, data: action.data };

    case FETCHED:
      return { ...state, activeItem: action.data };
    
    case CREATED:
      return { ...state, data: [...state.data, action.data] };

    case UPDATED:
      return {
        ...state,
        data: state.data.map(item => {
          if (item[idField] === action.data[idField]) {
            return action.data;
          }
          return item;
        }),
      };
    
    case DELETED:
      return {
        ...state,
        data: state.data.filter(item => item[idField] !== action.data[idField]),
      };

    case LOADING:
      return { ...state, isLoading: action.data };

    case TRIGGER_ACTION_BEFORE:
      return { ...state, isLoading: true, dataError: null, itemError: null };

    case TRIGGER_ACTION_AFTER:
      return { ...state, isLoading: false };

    case DATA_FAILED:
      return { ...state, dataError: action.data };

    case ITEM_FAILED:
      return { ...state, itemError: action.data };

    default:
      return state;
  }
};

export const crudActions = (api) => {
  const getAll = (dispatch) => createAction({
    dispatch,
    action: api.get('list'),
    onSuccess: successHandler(FETCHED_ALL),
    onError: errorHandler(DATA_FAILED),
  });

  const get = (payload, dispatch) => createAction({
    dispatch,
    action: api.get('get', payload),
    onSuccess: successHandler(FETCHED),
    onError: errorHandler(ITEM_FAILED),
  });

  const create = (payload, dispatch) => createAction({
    dispatch,
    action: api.post('create', payload),
    onSuccess: successHandler(CREATED),
    onError: errorHandler(ITEM_FAILED),
  });

  const update = (payload, dispatch) => createAction({
    dispatch,
    action: api.put('update', payload),
    onSuccess: successHandler(UPDATED),
    onError: errorHandler(ITEM_FAILED),
  });

  const remove = (payload, dispatch) => createAction({
    dispatch,
    action: api.delete('delete', payload),
    onSuccess: successHandler(DELETED),
    onError: errorHandler(ITEM_FAILED),
  });

  const logs = (payload) => {
    return api.get('logs', payload);
  };

  return {
    getAll,
    get,
    create,
    update,
    remove,
    logs,
  };
};

const createAction = ({ action, dispatch, onSuccess, onError }) => {
  if (typeof action === 'function') {
    action = action();
  }

  dispatch({ type: TRIGGER_ACTION_BEFORE });

  return Promise.resolve(action)
    .then(data => {
      if (typeof onSuccess === 'function') {
        onSuccess(data, dispatch);
      }
      return data;
    })
    .catch(err => {
      if (typeof onError === 'function') {
        onError(err, dispatch);
      }
    })
    .finally(() => {
      dispatch({ type: TRIGGER_ACTION_AFTER });
    });
};

const successHandler = (actionType) => (data, dispatch) => {
  dispatch({ type: actionType, data });
};

const errorHandler = (actionType) => (err, dispatch) => {
  dispatch({ type: actionType, data: err });
};
