import React from 'react';
import { Tag } from 'antd';

const mapStateToType = (state) => ({
  UNLOADING: 'success',
  LOADING: 'success',
  STOPPED: 'error',
  FINISHED: '',
})[state];


const ContainerStateTag = ({ container }) => (
  <Tag color={mapStateToType(container.container_state)} style={{ textTransform: 'capitalize' }}>
    {container.container_state.toLowerCase()}
    {'FINISHED' !== container.container_state ? ` (~${(container.unloaded_percent * 100).toFixed(0)}%)` : ''}
  </Tag>
);

export default ContainerStateTag;
