import UnloadStatsPage from './Stats.page';
import UnloadDoorDetailPage from './Details.page';
import ContainerDetailsPage from './ContainerDetails.page';

export const UNLOADS_PATH = '/unloads';

UnloadStatsPage.path = UNLOADS_PATH;
UnloadDoorDetailPage.path = '/unloads/:id';
ContainerDetailsPage.path = '/containers/:id';

export default {
  pages: {
    UnloadStatsPage,
    UnloadDoorDetailPage,
    ContainerDetailsPage,
  }
};
