import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

import Logo from './components/Logo';

const PublicBaseLayout = styled(Layout)`
  height: 100vh;
  background-color: unset;
`;

const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  & > :first-child {
    padding: 8% 0 80px 0;
  }
`;

const PublicLayout = ({ children }) => {
  return (
    <PublicBaseLayout>
      <Content>
        <Logo />
        {children}
      </Content>
    </PublicBaseLayout>
  );
};

export default PublicLayout;
