import {
  USER_LOGIN,
  USER_LOGOUT,
  PASSWORD_RESET_REQUIRED,
  PASSWORD_FORGOT_REQUEST,
} from './constants';

export const initialState = {
  currentUser: null,
  isAuthenticated: false,
  requiresPasswordReset: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        currentUser: action.data,
        isAuthenticated: true,
      };

    case USER_LOGOUT:
      return {
        ...state,
        currentUser: null,
        isAuthenticated: false,
      }

    case PASSWORD_RESET_REQUIRED:
      return {
        ...state,
        currentUser: action.data,
        requiresPasswordReset: true,
      };

    case PASSWORD_FORGOT_REQUEST:
      return {
        ...state,
        currentUser: action.data,
      };

    default: 
      return state;
  }
};

authReducer.initialState = initialState;
export default authReducer;
