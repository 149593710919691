import React from 'react';
import { Input, Button, Form } from 'antd';
import Icon from 'components/Icon';

const ForgotPasswordForm = ({ onSubmit, isLoading }) => (
  <Form
    layout="vertical"
    hideRequiredMark={true}
    onFinish={onSubmit}
    style={{ width: '360px' }}
  > 
    <Form.Item
      name="username"
      label="Email"
      rules={[
        {
          required: true,
          message: 'Please enter your email',
        },
      ]}
    >
      <Input size="large" prefix={<Icon name="mail" size={16} />} placeholder="Enter your email" autoComplete="nope" />
    </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit" size="large" style={{ width: '100%' }} loading={isLoading} disabled={isLoading}>
        Send Password Reset Code
      </Button>
    </Form.Item>
  </Form>
);

export default ForgotPasswordForm;
