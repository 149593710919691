import React, { useState } from 'react';

import AdminLayout from 'layouts/Admin.layout';
import Table from 'components/Table';
import Button from 'components/Button';
import Search from 'components/Search';
import { EditAction, DeleteAction } from './components/TableActions';

const employeeFields = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Location Name',
    dataIndex: 'location_name',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
];

const EmployeesPage = () => {
  const [search, setSearch] = useState('');
  const [data] = useState([]);
  
  return (  
    <AdminLayout
      title="Employees"
      icon="users"
      extra={[
        <Search placeholder="Search employee" value={search} onChange={e => setSearch(e.target.value)} />,
        <Button type="primary" icon="plus">Add new employee</Button>
      ]}
    >
      <Table
        columns={employeeFields}
        dataSource={data}
        actions={[
          EditAction,
          DeleteAction,
        ]}
        actionsProps={{
          resource: 'employee',
        }}
      />
    </AdminLayout>
  );
};

export default EmployeesPage;
