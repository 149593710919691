import React from 'react';
import styled from 'styled-components';
import * as antd from 'antd';

import Icon from './Icon';

const BasePageHeader = styled(antd.PageHeader)`
  padding-top: 28px;
`;

const BasePageHeaderTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PageHeader = ({ icon, title, children, ...props }) => {
  return (
    <BasePageHeader
      title={(
        <TitleWrapper>
          {icon ? <Icon
            name={icon}
            size={32}
            style={{ marginRight: '12px' }}
            color="#B9C2C9"
          /> : null}
          <BasePageHeaderTitle>{title}</BasePageHeaderTitle>
        </TitleWrapper>
      )}
      {...props}
      avatar={null}
    >
      {children}
    </BasePageHeader>
  );
};

export default PageHeader;
