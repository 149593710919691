import { format as dateFnsFormat } from 'date-fns';

export const toTimestamp = (date) => {
  if (typeof date === 'number') {
    if (date.toString().length === 13) return date;
    else return date * 1000;
  }
  if (date instanceof Date) {
    return date.getTime();
  }
  throw new TypeError('Invalid date: ', date);
};

export const formatDate = (date, format, options) => {
  return dateFnsFormat(date, format, options);
};

export const getSeconds = (date, inSecs = false) => {
  const tsInSec = inSecs ? date : Math.floor(toTimestamp(date) / 1000);
  return tsInSec % 60;
};

export const getMinutes = (date, inSecs = false) => {
  const tsInSec = inSecs ? date : Math.floor(toTimestamp(date) / 1000);
  return Math.floor(tsInSec / 60) % 60;
};

export const getHours = (date, inSecs = false) => {
  const tsInSec = inSecs ? date : Math.floor(toTimestamp(date) / 1000);
  return Math.floor(tsInSec / (60 * 60));
};

export const toSeconds = (date) => {
  const ts = toTimestamp(date);
  return Math.floor(ts / 1000);
};
