import React, { useState, useEffect, useCallback } from 'react';

import useFilters from 'hooks/useFilters';
import useForm, { FormMode } from 'hooks/useForm';
import useDoors from 'state/doors';
import useLocations from 'state/locations';

import AdminLayout from 'layouts/Admin.layout';
import FormModal from 'components/FormModal';
import Table from 'components/Table';
import Button from 'components/Button';
import Search from 'components/Search';
import DoorForm from './components/Door.form';
import { EditAction, DeleteAction } from './components/TableActions';

import { mapLocationsToOptions } from 'helpers/mapToOptions';
import { DOOR_TABLE_COLUMNS } from './constants';

const modalTitle = {
  create: 'Add new door',
  edit: 'Update door',
};

const submitButtonText = {
  create: 'Add door',
  edit: 'Update door',
};

const useDoorsFilters = useFilters([
  'door_id',
  'name',
  'location_name',
]);

const DoorsPage = () => {
  const doors = useDoors();
  const locations = useLocations();
  const [data, setData] = useState([]);
  const [currentItem, setCurrenItem] = useState(null);
  const filters = useDoorsFilters(data);
  const [form] = useForm({
    onSubmit: (values) => {
      const save = form.mode === FormMode.create
        ? doors.actions.create({ ...values, is_active: 1 })
        : doors.actions.update({ ...currentItem, ...values });

      save.then(() => {
        form.reset();
        setCurrenItem(null);
      });
    }
  });

  useEffect(() => {
    doors.actions.getAll();
    locations.actions.getAll();
  }, [doors.actions, locations.actions]);

  useEffect(() => {
    if (doors.state.data && locations.state.data) {
      const locationsData = locations.state.data;
      const doorsData = doors.state.data.map(door => ({
        ...door,
        location_name: (locationsData.find(l => l.location_id === door.location_id) || {}).location_name,
      }));
      setData(doorsData);
    }
  }, [doors.state.data, locations.state.data]);
  
  const onAction = useCallback((actionType, item) => {
    switch (actionType) {
      case EditAction.actionType:
        setCurrenItem(item);
        form.edit(item);
      break

      case DeleteAction.actionType:
        doors.actions.remove({
          door_id: item.door_id,
        });
      break;

      default: // make eslint happy :)
    }
  }, [form, doors.actions]);

  return (  
    <AdminLayout
      title="Doors"
      icon="airplay"
      extra={[
        <Search placeholder="Search door" value={filters.search} onChange={value => filters.setSearch(value)} />,
        <Button type="primary" icon="plus" onClick={form.create}>Add new door</Button>
      ]}
    >
      <FormModal
        title={`${modalTitle[form.mode]}`}
        form={form}
        submitText={`${submitButtonText[form.mode]}`}
        onClose={() => form.reset()}
      >
        <DoorForm form={form} locationOptions={mapLocationsToOptions(locations.state.data)} />
      </FormModal>
      <Table
        loading={doors.state.isLoading || locations.state.isLoading}
        columns={DOOR_TABLE_COLUMNS}
        dataSource={filters.data}
        actions={[
          EditAction,
          DeleteAction,
        ]}
        actionsProps={{
          resource: 'door',
        }}
        onAction={onAction}
        rowKey="door_id"
      />
    </AdminLayout>
  );
};

export default DoorsPage;
