export const STATS_OK = 'STATS_OK';
export const STATS_FAILED = 'STATS_FAILED';
export const IS_LOADING = 'STATS_LOADING';

export const STATS_DOORS = 'STATS_DOORS';
export const STATS_EMPLOYESS = 'STATS_EMPLOYEES';
export const STATS_TEAMS = 'STATS_TEAMS';
export const STATS_UNLOADS = 'STATS_UNLOADS';
export const STATS_EMPLOYESS_LIVE=  'STATS_EMPLOYEES_LIVE';
export const STATS_DOOR_DETAILS = 'STATS_DOOR_DETAILS';
export const STATS_EMPLOYEE_DETAILS = 'STATS_EMPLOYEE_DETAILS';
export const STATS_CONTAINER_DETAILS = 'STATS_CONTAINER_DETAILS';