import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, Typography, Space, Tag, Avatar, Tooltip } from 'antd';
import { formatDate, getHours, getMinutes, getSeconds } from 'helpers/date';

import { DOOR_STATE } from '../constants';

const { Text } = Typography;

const DoorCardBase = styled(Card)`
  background: #FFFFFF;
  border: none;
  border-radius: 8px;

  & .ant-card-body {
    padding: 0;
    margin: 0;
    min-height: 100%;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }
`;

const DoorCardContent = styled.div`
  display: flex;
  min-height: 80px;
  align-items: stretch;
`;

const InfoBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  justify-content: flex-start;

  & .ant-typography.status {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #45494D;
  }

  & .ant-typography {
    font-size: 12px;
    line-height: 20px;
    color: #45494D;
  }

  & .ant-tag {
    font-size: 12px;
    line-height: 18px;
    width: 100%;
    text-align: center;
  }
`;

const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: ${props => colorNames[props.color] || props.color};

  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
`;

const Workers = styled(Space)`
  flex-wrap: wrap;
  margin-right: 12px;
`;

// const stateColors = {
//   empty: '#828C94',
//   stopped: '#F5222D',
//   veryLow: '#FA8C16',
//   low: '#FADB14',
//   normal: '#52C41A',
// };

const UnloadingInfoBox = ({ door }) => {
  const timeToDock = door.unload_duration + door.dock_start_iddle;

  return (
    <InfoBox>
      <Space>
        <Tooltip placement="top" title={'Time Docked'} trigger="hover">
        <Text className="status">{getHours(timeToDock)}h {getMinutes(timeToDock)}m</Text>
        </Tooltip>
        <Tag color="processing">~{(door.unloaded_percent * 100).toFixed(0)}%</Tag>
      </Space>
      {door.started_at && <Text>Unload started at: {formatDate(door.started_at * 1000, 'HH:mm')}</Text>}
      {door.docked_at && <Text>Docked at: {formatDate(door.docked_at * 1000, 'HH:mm')}</Text>}
      {door.relative_speed && <Text>
        Unload Speed:&nbsp;
        <Text style={{ textTransform: 'capitalize', color: speedColors[door.relative_speed] }}>
          {door.relative_speed.toLowerCase().replace('_', ' ')}
        </Text>
      </Text>}
    </InfoBox>
  );
};

const StoppedInfoBox = ({ door }) => {
  const timeToDock = door.unload_duration + door.dock_start_iddle;

  return (
    <InfoBox>
      <Space>
        <Tooltip placement="top" title={'Time Docked'} trigger="hover">
          <Text className="status">{getHours(timeToDock)}h {getMinutes(timeToDock)}m</Text>
        </Tooltip>
        <Tag color="error">Stopped</Tag>
        <Tag color="processing">~{(door.unloaded_percent * 100).toFixed(0)}%</Tag>
      </Space>
      {door.started_at && <Text>Unload started at: {formatDate(door.started_at * 1000, 'HH:mm')}</Text>}
      {door.docked_at && <Text>Docked at: {formatDate(door.docked_at * 1000, 'HH:mm')}</Text>}
      {door.relative_speed && <Text>
        Unload Speed:&nbsp;
        <Text style={{ textTransform: 'capitalize', color: speedColors[door.relative_speed] }}>
          {door.relative_speed.toLowerCase().replace('_', ' ')}
        </Text>
      </Text>}
    </InfoBox>
  );
};

const EmptyInfoBox = ({ door }) => {
  const emptyTime = (new Date() / 1000) - door.docked_at || 0;

  return (
    <InfoBox>
      <Space>
        <Text className="status">Empty</Text>
      </Space>
      <Text>Last dock at: {door.docked_at ? formatDate(door.docked_at * 1000, 'HH:mm') : '-'}</Text>
      <Text>Empty Time: {door.docked_at ? `${getMinutes(emptyTime)}m ${getSeconds(emptyTime)}s` : '-'}</Text>
    </InfoBox>
  );
};

const infoBoxes = {
  [DOOR_STATE.UNLOADING]: UnloadingInfoBox,
  [DOOR_STATE.STOPPED]: StoppedInfoBox,
  [DOOR_STATE.EMPTY]: EmptyInfoBox,
};

const colorNames = {
  [DOOR_STATE.STOPPED]: '#F5222D',
  [DOOR_STATE.EMPTY]: '#828C94',
  [DOOR_STATE.UNLOADING]: '#52C41A',
};

const speedColors = {
  'VERY_SLOW': '#F5222D',
  'SLOW': '#FA8C16',
  'NORMAL': '#52C41A',
  'FAST': '#EB2F96',
};

const DoorCard = ({ door, ...rest }) => {
  const [workers] = useState(() => {
    let workers = [], more = [];
    const employeeNames = door.employees.map(e => e.full_name);

    if (employeeNames.length <= 4) {
      workers = [...employeeNames];
    } else {
      workers = employeeNames.slice(0, 3);
      more = employeeNames.slice(3);
    }
    return [workers, more];
  });

  const InfoBox = infoBoxes[door.door_state] || UnloadingInfoBox;
  const statusColor = colorNames[door.door_state] || colorNames[DOOR_STATE.UNLOADING];
  
  return (
    <DoorCardBase {...rest}>
      <DoorCardContent>
        <Status style={{ flexBasis: '80px' }} color={statusColor}>
          <span>{door.name}</span>
        </Status>
        <InfoBox door={door} />
        {door.door_state !== DOOR_STATE.EMPTY ? <Workers>
          {
            workers[0].map((name, i) => <Tooltip
                key={i}
                placement="top"
                title={name}
                trigger="hover"
              >
                <Avatar>
                  {getNameInitials(name)}
                </Avatar>
              </Tooltip>
            )
          }
          {
            workers[1].length ? (
              <Tooltip
                placement="top"
                title={workers[1].map((e, i) => <span key={i}>{e.full_name}<br/></span>)}
                trigger="hover"
              >
                <Avatar>+{workers[1].length}</Avatar>
              </Tooltip>
            ) : null
          }
        </Workers> : null}
      </DoorCardContent>
    </DoorCardBase>
  );
};

const getNameInitials = (fullName) => {
  if (!fullName) return '';
  return fullName.split(' ').map(i => i.charAt(0).toUpperCase()).slice(0, 2).join('');
};

export default DoorCard;
