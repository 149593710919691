import useModel from 'hooks/useModel';
import createApi from 'services/api';
import { crudActions, crudReducer } from 'state/crudHelper';

const api = createApi({
  baseUrl: 'admin/accounts',
  headers: {
    'Content-Type': 'application/json'
  },
});

const initialState = {
  isLoading: false,
  dataError: null,
  itemError: null,
  data: [],
  activeItem: null,
};

const reducer = (state = initialState, action, attributes) => {
  switch (action.type) {
    default:
      return crudReducer(state, action, attributes);
  }
};

export default useModel({
  reducer,
  actions: {
    ...crudActions(api),
  }
}, {
  idField: 'user_id',
  fields: [
    {
      title: 'User ID',
      key: 'user_id',
    },
    {
      title: 'Full Name',
      key: 'full_name',
    },
    {
      title: 'Email',
      key: 'email',
    },
    {
      title: 'Location',
      key: 'location_path',
    },
    {
      title: 'Active',
      key: 'is_active',
      tableView: false,
    },
    {
      title: 'Notes',
      key: 'notes',
      tableView: false,
    }
  ]
});
