import React from 'react';
import { Input, Form } from 'antd';

import Select from 'components/Select';

const UserForm = ({ form, locationOptions = [] }) => (
  <Form
    form={form}
    layout="vertical"
    hideRequiredMark={true}
    onFinish={form.onSubmit}
  >
    <Form.Item
      label="Full Name"
      name="full_name"
      rules={[
        {
          required: true,
          message: 'User full name is required',
        },
      ]}
    >
      <Input placeholder="Enter full name" />
    </Form.Item>

    <Form.Item
      label="Email"
      name="email"
      rules={[
        {
          required: true,
          message: 'User email is required',
        },
      ]}
    >
      <Input placeholder="Enter email" />
    </Form.Item>

    <Form.Item
      label="Location"
      name="location_id"
      rules={[
        {
          required: true,
          message: 'Location is required'
        }
      ]}
      >
        <Select options={locationOptions} placeholder="Select Location" />
      </Form.Item>
  </Form>
);

export default UserForm;