import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';

import { FormMode } from 'hooks/useForm';

const FormModal = ({ form, children, submitText, closeText, onClose, ...rest }) => {
  const [show, setShow] = useState(false);

  const onCancel = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      if (typeof onClose === 'function') onClose();
    }, 200); // wait for modal animation
  }, [onClose]);

  useEffect(() => {
    if ([FormMode.create, FormMode.edit].includes(form.mode)) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [form.mode]);

  return (
    <Modal
      visible={show}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {closeText}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {submitText}
        </Button>
      ]}
      {...rest}
    >
      {children}
    </Modal>
  );
};

FormModal.propTypes = {
  form: PropTypes.object.isRequired,
  children: PropTypes.node,
  submitText: PropTypes.string.isRequired,
  closeText: PropTypes.string,
  onClose: PropTypes.func,
};

FormModal.defaultProps = {
  closeText: 'Cancel',
};

export default FormModal;
