import { api as awsApi } from './aws';

const API_DEFAULT_METHODS = [
  'get',
  'post',
  'put',
  'delete',
];

const createApi = (opts) => {
  const api = {
    baseUrl: `/${trimSlashes(opts.baseUrl || '')}`,
    headers: opts.headers || {},
  };

  API_DEFAULT_METHODS.forEach(methodName => {
    api[methodName] = async (path, params, headers) => {
      path = trimSlashes(path || '');
      headers = Object.assign({}, api.headers, headers);

      return request({
        url: `${api.baseUrl}/${path}`,
        method: methodName,
        query: methodName === 'get' ? params : null,
        body: methodName === 'get' ? null : params,
        headers,
      });
    };
  });

  return api;
};

const request = async ({ url, method = 'get', query, body, headers = {} }) => {
  let res = {};
  try {
    res = await awsApi[method]('lambda', url, {
      headers,
      queryStringParameters: query,
      body,
    });

    console.log(`Response for ${method} ${url}:`, res);
  } catch (err) {
    console.log(err);
  }

  if (!res.Success) {
    throw new Error(res.Error || 'Something went wrong!');
  }

  return res.Payload;
};

const trimSlashes = path => path.replace(/^\/+|\/+$/gm, '');

export default createApi;
