export const aggregateDoorDetailsStats = (stats = []) => {
  if (!Array.isArray(stats)) return {};

  // TODO: filter stats to include only those with status finished
  const filteredStats = stats.filter(stat => true);

  return {
    totalContainers: filteredStats.length,
    totalBoxes: filteredStats.reduce((total, item) => total + item.unloaded_boxes, 0),
  };
};


export const aggregateDoorStats =  (stats = []) => {
  return {

  };
};