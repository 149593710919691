import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Tooltip } from 'antd';

import { formatDate, getHours, getMinutes } from 'helpers/date';

const { Text } = Typography;

export const STATS_FILTER_LEVELS = [
  {
    label: 'Individuals',
    value: 'individuals',
  },
  {
    label: 'Teams',
    value: 'teams',
  }
];

export const ACTIVE_INDIVIDUALS_TABLE_COLUMNS = [
  {
    title: 'Employee',
    dataIndex: 'full_name',
    key: 'employee',
    render: (fullName, { employee_id }) => (
      <Link to={`/individuals/${employee_id}`}>{fullName}</Link>
    ),
  },
  {
    title: 'Door',
    key: 'door_name',
    dataIndex: 'name',
    render: (doorName, { door_id }) => (
      <Link to={`/unloads/${door_id}`}>{doorName}</Link>
    )
  },
  {
    title: 'Container Code',
    key: 'container_code',
    dataIndex: 'container_code',
    render: (containerCode, { container_id }) => containerCode ? (
      <Link to={`/containers/${container_id}`}>{containerCode}</Link>
    ) : '-',
  },
  {
    title: 'Last seen',
    dataIndex: 'last_time_seen',
    align: 'center',
    render: lastTimeSeen => lastTimeSeen ? formatDate(lastTimeSeen * 1000, 'HH:mm') : '-',
  },
  {
    title: 'Hours seen',
    dataIndex: 'tot_mins_seen',
    align: 'right',
    render: totMins => {
      if (!totMins) return '-'; 
      const totSec = Math.floor(totMins * 60);
      
      return `${getHours(totSec)}h ${getMinutes(totSec)}m`;
    },
  },
];

export const INDIVIDUAL_TABLE_COLUMNS = [
  {
    title: 'Employee',
    dataIndex: 'full_name',
    key: 'full_name',
    render: (fullName, { employee_id }) => (
      <Link to={`individuals/${employee_id}`}>
        {fullName}
      </Link>
    ),
  },
  {
    title: 'Container Unloads',
    key: 'tot_containers',
    dataIndex: 'tot_containers',
  },
  {
    title: 'Container Type',
    key: 'container_type',
    dataIndex: 'container_class',
  },
  {
    title: 'Performance Score',
    key: 'performance_score',
    dataIndex: 'score',
    render: (score, { score_color, score_notes }) => {
      if (!score) return '-';
      const scoreColors = { green: '#52C41A', yellow: '#FA8C16', red: '#F5222D' };

      return (
        <Tooltip placement="top" title={score_notes} trigger="hover">
          <Text style={{ color: scoreColors[score_color] }}>
            {Math.round(score * 100)}
          </Text>
        </Tooltip>
      );
    }
  },
  {
    title: 'Unload Avg. Duration',
    key: 'avg_duration',
    dataIndex: 'avg_duration',
    render: (avgDuration) => avgDuration ? `${getHours(avgDuration)}h ${getMinutes(avgDuration)}m` : '-',
  },
  {
    title: 'Activity Hours',
    dataIndex: 'avg_minutes_seen',
    align: 'right',
    render: (avgTime) => {
      if (!avgTime) return '-';
      avgTime = Math.round(avgTime * 60);

      let hrs = getHours(avgTime);
      let mins = getMinutes(avgTime);

      if (hrs > 0) hrs = `${hrs}h`
      else hrs = '';

      mins = `${mins}m`;
      
      return [hrs, mins].join(' ');
    }
  }
];

export const TEAMS_TABLE_COLUMNS = [
  {
    title: 'Team',
    dataIndex: 'employees',
    key: 'team',
    width: '20%',
    render: (employees) => (
      <Text>
        {
          employees.map(({ full_name, employee_id }, i) => (
            <span key={i}>
              <Link to={`/individuals/${employee_id}`}>
                {full_name}
              </Link>
              {i < employees.length - 1 ? ', ' : ''}
            </span>
          ))
        }
      </Text>
    ),
  },
  {
    title: 'Container type',
    key: 'container_type',
    dataIndex: 'container_class',
  },
  {
    title: 'Unloads',
    key: 'tot_containers',
    dataIndex: 'tot_containers',
    align: 'right',
  },
  {
    title: 'Average unload time/container',
    key: 'avg_duration',
    dataIndex: 'avg_duration',
    align: 'right',
    render: (avgDuration) => avgDuration ? `${getHours(avgDuration)}h ${getMinutes(avgDuration)}m` : '-',
  },
  {
    title: 'Average labor expended/container',
    key: 'avg_minutes_seen',
    dataIndex: 'avg_minutes_seen',
    align: 'right',
    render: (avgTime) => {
      if (!avgTime) return '-';
      avgTime = Math.round(avgTime * 60);

      let hrs = getHours(avgTime);
      let mins = getMinutes(avgTime);

      if (hrs > 0) hrs = `${hrs}h`
      else hrs = '';

      mins = `${mins}m`;
      
      return [hrs, mins].join(' ');
    }
  },
  {
    title: 'Performance Score',
    key: 'performance_score',
    dataIndex: 'score',
    align: 'right',
    render: (score, { score_color, score_notes }) => {
      if (!score) return '-';
      const scoreColors = { green: '#52C41A', yellow: '#FA8C16', red: '#F5222D' };

      return (
        <Tooltip placement="topRight" title={score_notes} trigger="hover">
          <Text style={{ color: scoreColors[score_color] }}>
            {Math.round(score * 100)}
          </Text>
        </Tooltip>
      );
    }
  },
];


export const TOP_TEAMMATES_COLUMNS = [
  {
    title: 'Employee',
    dataIndex: 'full_name',
    key: 'employee',
    render: (fullName, { employee_id }) => (
      <Link to={`/individuals/${employee_id}`}>{fullName}</Link>
    ),
    width: '80%',
  },
  {
    title: 'Score',
    dataIndex: 'score',
    align: 'right',
    render: (score, { score_color, score_notes }) => {
      if (!score) return '-';
      const scoreColors = { green: '#52C41A', yellow: '#FA8C16', red: '#F5222D' };

      return (
        <Tooltip placement="topRight" title={score_notes} trigger="hover">
          <Text style={{ color: scoreColors[score_color] }}>
            {Math.round(score * 100)}
          </Text>
        </Tooltip>
      );
    }
  },
];

export const TOP_CONTAINER_TYPES_COLUMNS = [
  {
    title: 'Container',
    dataIndex: 'container_class',
    key: 'container_class',
    render: (containerType) => (
      <Text>{containerType}</Text>
    ),
    width: '80%',
  },
  {
    title: 'Score',
    dataIndex: 'score',
    align: 'right',
    render: (score, { score_color, score_notes }) => {
      if (!score) return '-';
      const scoreColors = { green: '#52C41A', yellow: '#FA8C16', red: '#F5222D' };

      return (
        <Tooltip placement="topRight" title={score_notes} trigger="hover">
          <Text style={{ color: scoreColors[score_color] }}>
            {Math.round(score * 100)}
          </Text>
        </Tooltip>
      );
    }
  },
];

export const WORKING_TIMES_COLUMNS = [
  {
    title: 'Start Date and Time',
    dataIndex: 'started_at',
    render: (startDt) => startDt ? formatDate(startDt * 1000, 'dd/MM/yyyy HH:mm') : '-',
  },
  {
    title: 'End Date and Time',
    dataIndex: 'ended_at',
    render: (endDt) => endDt ? formatDate(endDt * 1000, 'dd/MM/yyyy HH:mm') : '-',
  },
  {
    title: 'Team',
    dataIndex: 'employees',
    render: (employees) => (
      <Text>
        {
          employees.map(({ full_name, employee_id }, i) => (
            <span key={i}>
              <Link to={`/individuals/${employee_id}`}>
                {full_name}
              </Link>
              {i < employees.length - 1 ? ', ' : ''}
            </span>
          ))
        }
      </Text>
    ),
  },
  {
    title: 'Boxes Unloaded',
    key: 'unloaded_boxes',
    dataIndex: 'unloaded_boxes',
    align: 'right',
  },
  {
    title: 'Hours seen',
    key: 'tot_mins_seen',
    dataIndex: 'tot_mins_seen',
    align: 'right',
    render: totMins => {
      if (!totMins) return '-'; 
      const totSec = Math.floor(totMins * 60);
      
      return `${getHours(totSec)}h ${getMinutes(totSec)}m`;
    },
  },
  {
    title: 'Door',
    key: 'door_name',
    dataIndex: 'door_name',
    align: 'right',
    render: (doorName, { door_id }) => (
      <Link to={`/unloads/${door_id}`}>{doorName}</Link>
    )
  },
];