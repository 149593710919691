import React from 'react';
import PropTypes from 'prop-types';
import IconWrapper from '@ant-design/icons';
import feather from 'feather-icons';

const createIconComponent = (name, attrs = {}) => {
  const icon = feather.icons[name]
  const { size, color, fill, className } = attrs;
  let width, height;

  if (size) {
    if (typeof size === 'number') {
      width = height = size;
    } else {
      width = size[0];
      height = size[1];
    }
  }

  if (!icon) {
    throw new Error(`Unknown icon ${name}`);
  }

  return () => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill || 'none'}
      stroke={color || "currentColor"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`feather feather-${name} ${className}`}
    >
      <g dangerouslySetInnerHTML={{ __html: icon.contents }} />
    </svg>
  );
};

const Icon = ({ name, ...props }) => (
  <IconWrapper {...props} component={createIconComponent(name, props)} />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  className: PropTypes.string,
  fill: PropTypes.string
};

export default Icon;
