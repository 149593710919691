import React from 'react';
import { Typography, Tooltip } from 'antd';
import Link from 'components/Link';
import { formatDate, getHours, getMinutes, getSeconds } from 'helpers/date';

import colors from 'config/colors';
// import StateTag from './components/StateTag';

const { Text } = Typography;

export const DOOR_STATE = {
  UNLOADING: 'UNLOADING',
  STOPPED: 'STOPPED',
  EMPTY: 'EMPTY',
};

export const UNLOAD_RELATION_FILTERS = []

export const UNLOAD_FILTER_LEVELS = [
  {
    label: 'Details',
    value: 'details',
  },
  {
    label: 'Stats',
    value: 'stats',
  }
];

export const UNLOAD_DETAILS_COLUMNS = [
  {
    title: 'Container Code',
    key: 'container_code',
    dataIndex: 'container_code',
    render: (containerCode, { container_id }) => containerCode ? (
      <Link to={`/containers/${container_id}`}>{containerCode}</Link>
    ) : '-',
  },
  {
    title: 'Docked at',
    key: 'docked_at',
    dataIndex: 'docked_at',
    render: dockedAt => dockedAt ? formatDate(dockedAt * 1000, 'dd/MM/yyyy HH:mm') : '-',
  },
  {
    title: 'Idle time start',
    key: 'dock_start_iddle',
    dataIndex: 'dock_start_iddle',
    render: idleTime => {
      if (idleTime === undefined) return '-';
      
      let mins = getMinutes(idleTime);
      let secs = getSeconds(idleTime);

      if (mins > 0) mins = `${mins}m`;
      else mins = '';
      secs += 's';

      return `${mins} ${secs}`;
    }
  },
  {
    title: 'Unload duration',
    key: 'unload_duration',
    dataIndex: 'unload_duration',
    render: unload_duration => {
      if (!unload_duration) return '-';
      return `${getHours(unload_duration)}h ${getMinutes(unload_duration)}m`;
    }
  },
  {
    title: 'Idle time end',
    key: 'dock_end_iddle',
    dataIndex: 'dock_end_iddle',
    render: idleTime => {
      if (idleTime === undefined) return '-';
      
      let mins = getMinutes(idleTime);
      let secs = getSeconds(idleTime);

      if (mins > 0) mins = `${mins}m`;
      else mins = '';
      secs += 's';

      return `${mins} ${secs}`;
    }
  },
  {
    title: 'Undocked at',
    key: 'undocked_at',
    dataIndex: 'undocked_at',
    render: undockedAt => undockedAt ? formatDate(undockedAt * 1000, 'dd/MM/yyyy HH:mm') : '-',
  },
  {
    title: 'Boxes Unloaded',
    dataIndex: 'unloaded_boxes',
    align: 'right',
    render: boxes => typeof boxes === 'number' ? boxes : '-',
  },
  {
    title: 'Employees',
    dataIndex: 'employees',
    key: 'employees',
    align: 'right',
    render: (employees = []) => {
      if (!employees || !employees.length) {
        return <Text style={{ color: colors.primary }}>0</Text>;
      }

      return (
        <Tooltip placement="top" title={employees.length ? employees.map((e, i) =>
          <span key={i}>{e.full_name}<br/></span>
        ) : 'No employees'} trigger="hover">
          <Text style={{ color: colors.primary, cursor: 'pointer' }}>
              {employees.length}
          </Text>
        </Tooltip>
      );
    },
  },
];

export const EMPLOYEES_DETAILS_COLUMNS = [
  {
    title: 'Employee',
    dataIndex: 'name',
    key: 'name',
    render: (name, { id }) => <Link to={`/individuals/${id}`}>{name}</Link>,
  },
  {
    title: 'Last Seen',
    key: 'last_seen',
    dataIndex: 'last_seen',
    render: (lastSeen) => lastSeen ? formatDate(lastSeen * 1000, 'dd/MM/yyyy HH:mm') : '-',
  },
  {
    title: 'Container Unloads',
    key: 'tot_unloads',
    dataIndex: 'tot_unloads',
    align: 'right',
  },
  {
    title: 'Total time seen',
    key: 'tot_mins_seen',
    dataIndex: 'tot_mins_seen',
    align: 'right',
    render: (totMins) => totMins ? `${getHours(totMins * 60)}h ${getMinutes(totMins * 60)}m` : '-',
  }
];

export const UNLOAD_REALTION_TITLE = {
  'unloads': level => ({ details: 'Unloads', stats: 'Unloads Stats' })[level],
  'employees': () => 'Employees',
};

export const CONTAINER_EMPLOYEES_TABLE_COLUMNS = [
  {
    title: 'Employee',
    key: 'full_name',
    dataIndex: 'full_name',
    render: (name, { employee_id }) => <Link to={`/individuals/${employee_id}`}>{name}</Link>,
  },
  {
    title: 'First Seen',
    key: 'first_seen',
    dataIndex: 'first_seen',
    render: (firstSeen) => firstSeen ? formatDate(firstSeen * 1000, 'dd/MM/yyyy HH:mm') : '-',
  },
  {
    title: 'Last Seen',
    key: 'last_seen',
    dataIndex: 'last_seen',
    render: (lastSeen) => lastSeen ? formatDate(lastSeen * 1000, 'dd/MM/yyyy HH:mm') : '-',
  },
  {
    title: 'Total',
    key: 'tot_mins_seen',
    dataIndex: 'tot_mins_seen',
    align: 'center',
    render: (totMins) => totMins ? `${getHours(totMins * 60)}h ${getMinutes(totMins * 60)}m` : '-',
  }
];

export const DOOR_DETAILS_COLUMNS = [
  {
    title: 'Door',
    dataIndex: 'name',
    align: 'center',
    render: (doorName, { door_id }) => (
      <Link to={`/unloads/${door_id}`}>
        {doorName}
      </Link>
    )
  },
  // {
  //   title: 'Status',
  //   dataIndex: ['current_state', 'door_state'],
  //   key: 'door_state',
  //   align: 'center',
  //   render: (currentState, { current_state: { unloaded_percent }}) => {
  //     if (!currentState) return '-';
  //     else return <StateTag door={{ door_state: currentState || '', unloaded_percent }} />
  //   }
  // },
  {
    title: 'Boxes Unloaded',
    dataIndex: 'tot_boxes',
    align: 'right',
  },
  {
    title: 'Expended labor hour/container',
    dataIndex: 'avg_man_minutes',
    align: 'right',
    render: (avgTime) => {
      if (!avgTime) return '-';
      avgTime = Math.round(avgTime * 60);

      let hrs = getHours(avgTime);
      let mins = getMinutes(avgTime);

      if (hrs > 0) hrs = `${hrs}h`
      else hrs = '';

      mins = `${mins}m`;
      
      return [hrs, mins].join(' ');
    }
  },
  {
    title: 'Containers Unloaded',
    dataIndex: 'tot_containers',
    align: 'right',
  },
  {
    title: 'Average time/container',
    dataIndex: 'avg_time_per_container',
    align: 'right',
    render: (avgTime) => {
      let hrs = getHours(avgTime);
      let mins = getMinutes(avgTime);
      let secs = getSeconds(avgTime);

      if (hrs > 0) hrs = `${hrs}h`
      else hrs = '';

      if (mins > 0) mins = `${mins}m`;
      else mins = '';

      secs += 's';

      return [hrs, mins, secs].join(' ');
    }
  },
  {
    title: 'Average box/min',
    dataIndex: 'avg_box_per_min',
    align: 'right',
    render: (avgBoxes) => avgBoxes ? avgBoxes.toFixed(2) : 0,
  },
  {
    title: 'Average Employees',
    dataIndex: 'avg_employees',
    align: 'right',
  },
];

