import React from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';

import Logo from './Logo';

const SideNavWrapper = styled.div`
  background-color: #fff;
  height: 100%;
  width: 100%;
  box-shadow: 1px 0px 0px #EFF2F5;
`;

const SideNavHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 28px;
`;

const SideNavMenu = styled(Menu)`
  height: calc(100% - 100px);
  padding: 0 16px;
  border-right: unset;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  & > :last-child {
    margin-top: auto;
  }
`;

const SideNavItem = styled(Menu.Item)`
  font-size: 16px;
  border-radius: 8px;
  min-height: 40px;

  &.ant-menu-item-active {
    background: #FAFBFC;
  }

  &.ant-menu-item-selected {
    background: #F5FDFF !important;
    border-radius: 8px;
    font-weight: 600;
  }

  &::after {
    display: none;
  }
`;

const SideNavSubMenu = styled(Menu.SubMenu)`
  border-radius: 8px;
  margin-top: 4px;
  margin-bottom: 8px;

  & .ant-menu-submenu-title {
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    margin-top: unset;
    margin-bottom: unset;
  }

  & .ant-menu-submenu-title:active {
    &:not(.ant-menu-submenu-selected) {
      background: #FAFBFC;
    }
  }

  &.ant-menu-submenu-active {
    background: #FAFBFC;

    & .ant-menu-submenu-title {
      border-radius: 8px; 
    }
  }

  &.ant-menu-submenu-selected {
    background: #F5FDFF;
    & > *, .ant-menu-item-active {
      background: #F5FDFF;
    }
  }

  & .ant-menu-submenu-arrow {
    color: #DAE0E6;
  }

  &.ant-menu-submenu-open {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: #FAFBFC;

    &:not(.ant-menu-submenu-selected) > * {
      background: #FAFBFC;
    }

    & > *:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    & > *:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  & ul > * {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    line-height: 36px !important;
    text-indent: 10px;

    &:last-child {
      margin-bottom: 4px !important;
    }
  }
`;

const SideNavGroup = styled(Menu.ItemGroup)`
  & .ant-menu-item-group-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #B9C2C9;
    padding: 8px 0px 0px 16px;
  }
`;

const SideNav = ({ menu = [], selectedMenuItemKey = '', onSelectedMenuItemChanged }) => {
  return (
    <SideNavWrapper>
      <SideNavHeader>
        <Logo />
      </SideNavHeader>
      <SideNavMenu
        inlineIndent={16}
        defaultSelectedKeys={[selectedMenuItemKey]}
        defaultOpenKeys={[selectedMenuItemKey.split('.')[0]]}
        forceSubMenuRender={true}
        mode="inline"
        onSelect={(data) => {
          if (typeof onSelectedMenuItemChanged === 'function')
            onSelectedMenuItemChanged(data);
        }}
      >
        {Object.keys(menu).map(key => renderSideNavItem(menu[key], key))}
      </SideNavMenu>
    </SideNavWrapper>
  );
};

const renderSideNavItem = (menuItem, menuItemKey, menuItemParentPath = '') => {
  const type = menuItem.type || 'item';

  switch (type) {
    case 'item': return (
      <SideNavItem
        key={menuItemKey}
        icon={menuItem.icon}
        path={[menuItemParentPath, menuItem.to].join('')}
        disabled={menuItem.disabled || false}
      >
        {menuItem.label}
      </SideNavItem>
    );

    case 'sub': return (
      <SideNavSubMenu key={menuItemKey} icon={menuItem.icon} title={menuItem.label}>
        {Object.keys(menuItem.items).map(key =>
          renderSideNavItem(
            menuItem.items[key],
            [menuItemKey, key].join('.'),
            [menuItemParentPath, menuItem.to || ''].join(''),
          )
        )}
      </SideNavSubMenu>
    );

    case 'section': return (
      <SideNavGroup key={menuItemKey} title={menuItem.label} icon={menuItem.icon}>
        {Object.keys(menuItem.items).map(key => 
          renderSideNavItem(
            menuItem.items[key],
            [menuItemKey, key].join('.'),
            [menuItemParentPath, menuItem.to || ''].join(''),
          )
        )}
      </SideNavGroup>
    );

    default:
      return null;
  }
};

export default SideNav;