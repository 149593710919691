import React from 'react';
import Icon from './Icon';
import RadioGroup from './RadioGroup';

export const LIST_MODE_OPTIONS = [
  {
    label: <Icon name="grid" size={16} />,
    value: 'grid',
  },
  {
    label: <Icon name="align-justify" size={16} />,
    value: 'table',
  }
];

const ListModeRadioGroup = ({ onChange, ...props }) => {
  return (
    <RadioGroup
      options={LIST_MODE_OPTIONS}
      optionType="button"
      buttonStyle="solid"
      onChange={e => onChange(e.target.value)}
      {...props}
    />
  );
};

export default ListModeRadioGroup;
