import React from 'react';
import { Button as ButtonBase } from 'antd';

import Icon from './Icon';

const Button = ({ icon, children, ...props }) => {
  if (typeof icon === 'string') {
    icon = <Icon name={icon} size={16} />
  } else if (typeof icon === 'object' && !React.isValidElement(icon)) {
    icon = <Icon {...icon} />
  }

  return (
    <ButtonBase icon={icon} {...props}>
      {children}
    </ButtonBase>
  );
};

export default Button;
