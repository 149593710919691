import React from 'react';
import styled from 'styled-components';
import { Tabs } from 'antd';

const UnloadTabs = styled(Tabs)`
  & .ant-tabs-nav {
    margin-bottom: 0;
  }

  & .ant-tabs-nav::before {
    border-bottom: none;
  }

  & .ant-tabs-content-holder {
    display: none;
  }
`;

const UnloadTabsFilter = (props) => (
  <UnloadTabs {...props}>
    <Tabs.TabPane tab="Unloads" key="unloads" />
    <Tabs.TabPane tab="Employees" key="employees" />
  </UnloadTabs>
);

export default UnloadTabsFilter;
