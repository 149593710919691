import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
// import { isMobile } from 'react-device-detect';
import { Layout } from 'antd';

import useAuth from 'state/auth';

import menu from 'config/menu';
import SideNav from './components/SideNav';

const BaseLayout = styled(Layout)`
  height: 100vh;
  background-color: #fff;
`;

const Sider = styled(Layout.Sider)`
  width: 250px;
  padding: 0;
  height: 100vh;
  position: fixed;
`;

const Content = styled(Layout.Content)`
  padding-left: 251px;
`;

const PrimaryLayout = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPathKey, setCurrentPathKey] = useState('');

  const [menuItemsByPath] = useState(() => {
    const itemsByPath = {};
    let unvisited = Object.entries(menu);

    do {
      let newPairs = [];
      unvisited.forEach(([key, item]) => {
        if (item.to) {
          itemsByPath[item.to] = key;
        } else if (item.items) {
          const subItems = Object.entries(item.items).map(item => [
            [key, item[0]].join('.'),
            item[1]
          ]);
          newPairs = newPairs.concat(subItems);
        }
      });
      unvisited = newPairs;
    } while (unvisited.length > 0);

    return itemsByPath;
  });

  useEffect(() => {
    if (location && location.pathname) {
      if (location.state && location.state.itemKey) {
        setCurrentPathKey(location.state.itemKey);
      } if (menuItemsByPath[location.pathname]) {
        setCurrentPathKey(menuItemsByPath[location.pathname]);
      }
    }
    setIsLoading(false);
  }, [location, menuItemsByPath]);

  const onSelectedMenuItemChanged = useCallback(({ item }) => {
    if (item && item.props && item.props.path) {
      if (item.props.path === '/logout') {
        auth.actions.logout();
      } else {
        history.push(item.props.path, {
          state: {
            itemKey: item.props.eventKey,
          }
        });
      }
    }
  }, [history, auth.actions]);

  return (
    <BaseLayout>
      <Sider theme="light" width={250}>
        {!isLoading && (
          <SideNav
            menu={menu}
            selectedMenuItemKey={currentPathKey}
            onSelectedMenuItemChanged={onSelectedMenuItemChanged}
          />
        )}
      </Sider>
      <Content>
        {children}
      </Content>
    </BaseLayout>
  );
};

export default PrimaryLayout;