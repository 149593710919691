import Amplify, { API, Auth } from 'aws-amplify';
import AWSConfig from 'config/aws';

/**
 * https://aws-amplify.github.io/docs/js/authentication
 */

// Configure client
Amplify.configure(AWSConfig);

export const api = API;
export const auth = Auth;
