import React from 'react';
import { Tag } from 'antd';

import { DOOR_STATE } from '../constants';

const mapStateToType = (state) => ({
  [DOOR_STATE.UNLOADING]: 'success',
  [DOOR_STATE.STOPPED]: 'error',
  [DOOR_STATE.EMPTY]: '',
})[state];


const StateTag = ({ door }) => (
  <Tag color={mapStateToType(door.door_state)} style={{ textTransform: 'capitalize' }}>
    {door.door_state.toLowerCase()}
    {DOOR_STATE.EMPTY !== door.door_state ? ` (~${(door.unloaded_percent * 100).toFixed(0)}%)` : ''}
  </Tag>
);

export default StateTag;
