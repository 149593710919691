import React, { useState, useEffect } from 'react';
import { Table, Space } from 'antd';

const TableWrapper = ({ actions, actionsProps, onAction, columns, ...props }) => {
  const [tableColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    const columnsWithActions = [...columns];
    
    if (actions && actions.length) {
      columnsWithActions.push({
        title: 'Actions',
        key: 'actions',
        align: 'right',
        render: item => <ActionsColumn
          currentItem={item}
          actions={actions}
          onAction={onAction}
          {...actionsProps}
        />
      });
    }
    
    setTableColumns(columnsWithActions);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, actions]);

  return (
    <Table columns={tableColumns} {...props} />
  );
};

const ActionsColumn = ({ actions, currentItem, onAction, ...rest }) => {
  const handleAction = (actionType) => {
    return (e) => {
      if (typeof onAction === 'function')
        onAction(actionType, currentItem);
    };
  };

  return (
    <Space size="small">
      {actions.map((Action, i) =>
        <Action key={i} handleAction={handleAction} item={currentItem} {...rest} />
      )}
    </Space>
  )
};

export default TableWrapper;