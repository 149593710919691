import React from 'react';
import { Radio } from 'antd';

const RadioGroup = ({ options, optionType, ...rest }) => {
  if (optionType !== 'button') {
    return <Radio.Group option={options} {...rest} />
  }

  return (
    <Radio.Group optionType={optionType} {...rest}>
      {
        options.map((option, key) => <Radio.Button
          key={key}
          value={option.value}
          disabled={option.disabled}>
          {option.label}
        </Radio.Button>)
      }
    </Radio.Group>
  );
};

export default RadioGroup;
