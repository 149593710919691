import { auth } from 'services/aws';

import {
  USER_LOGIN,
  USER_LOGOUT,
  PASSWORD_RESET_REQUIRED,
  PASSWORD_FORGOT_REQUEST,
} from './constants';

export const login = async (username, password, dispatch) => {
  const user = await auth.signIn(username, password);
  if (!user) throw new Error('Failed to login');

  if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
    dispatch({ type: PASSWORD_RESET_REQUIRED, data: getUserInfo(user) });
  } else {
    dispatch({ type: USER_LOGIN, data: getUserInfo(user) });
  }
};

export const logout = async (dispatch) => {
  await auth.signOut();
  dispatch({ type: USER_LOGOUT });
};

export const loggedInUser = async (dispatch) => {
  const user = await auth.currentAuthenticatedUser();
  dispatch({ type: USER_LOGIN, data: getUserInfo(user) });
};

export const resetPassword = async (newPassword, dispatch, { currentUser }) => {
  if (!currentUser || !currentUser.cognitoUser) {
    const err = new Error('No cognito user');
    err.code = 'INVALID_RESET_FLOW';
    throw err;
  }
  const user = await auth.completeNewPassword(currentUser.cognitoUser, newPassword);
  dispatch({ type: USER_LOGIN, data: getUserInfo(user) });
};

export const forgotPassword = async (username, dispatch) => {
  await auth.forgotPassword(username);
  dispatch({ type: PASSWORD_FORGOT_REQUEST, data: { username } });
};

export const forgotPasswordComplete = async (newPassword, resetCode, dispatch, { currentUser }) => {
  await auth.forgotPasswordSubmit(currentUser.username, resetCode, newPassword);
};

const getUserInfo = (user) => {
  const userInfo = {
    username: user.username,
    cognitoUser: user,
  };
  
  if (user.signInUserSession) {
    const idToken = user.signInUserSession.getIdToken();
    
    if (idToken && idToken.payload) {
      userInfo.isAdmin = idToken.payload['cognito:groups'] ? true : false;
      userInfo.displayName = idToken.payload['custom:display_name'] || user.username;
    }
  }
  
  return userInfo;
};
