import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { Row, Col, DatePicker, Table, Divider, Modal } from 'antd';

import colors from 'config/colors';
import useReports from 'state/reports';

import MainLayout from 'layouts/Main.layout';
import PageHeader from 'components/PageHeader';
import ViewSwitcher from 'components/ViewSwitcher';
import Grid from 'components/Grid';
import StatsCard from 'components/StatsCard';
import RadioGroup from 'components/RadioGroup';
import Button from 'components/Button';
import FilterBar from 'components/FilterBar';
import Link from 'components/Link';
import UnloadFilter from './components/UnloadTabsFilter';
import StateTag from './components/StateTag';

import { toSeconds } from 'helpers/date';
import { UNLOADS_PATH } from './index';
import { aggregateDoorDetailsStats } from './helpers';
import { UNLOAD_FILTER_LEVELS, UNLOAD_DETAILS_COLUMNS, EMPLOYEES_DETAILS_COLUMNS, UNLOAD_REALTION_TITLE } from './constants';

const UnloadDetailsPageHeader = styled(PageHeader)`
  & .ant-page-header-back {
    margin-left: 2px;
    margin-right: 12px;
  }

  & .ant-page-header-content {
    padding-top: unset;
    width: 148px;
    margin-left: 32px;
    margin-top: -12px;
  }
`;

const UnloadsDoorDetails = () => {
  const reports = useReports();
  const params = useParams();
  const history = useHistory();
  const [employeesModal, setEmployeesModal] = useState({ show: false, employees: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({ door: null, unloads: [], employees: [], totalContainers: 0, totalBoxes: 0 })
  const [filterEntity, setFilterEntity] = useState('unloads');
  const [filterLevel, setFilterLevel] = useState(UNLOAD_FILTER_LEVELS[0].value);
  const [dateRange, setDateRange] = useState({ start: moment().subtract(2, 'days'), end: moment() });
  const [unloadDetailsColumns] = useState(() => {
    return UNLOAD_DETAILS_COLUMNS.map(column => {
      if (column.key === 'employees') {
        return {
          ...column,
          onCell: (item) => ({ onClick: () => showEmployeesModal(item) }),
        };
      }
      return column;
    });
  });

  useEffect(() => {
    const id = parseInt(params.id);
    if (isNaN(id)) {
      history.replace(UNLOADS_PATH);
    }
  }, [params, history]);
  
  useEffect(() => {
    if (params.id && dateRange && dateRange.start && dateRange.end) {
      const { start, end } = dateRange;

      const from = toSeconds(new Date(Date.UTC(start.year(), start.month(), start.date())).getTime());
      const to = toSeconds(new Date(Date.UTC(end.year(), end.month(), end.date())).getTime());
      
      const doorId = parseInt(params.id, 10);
      if (isNaN(doorId)) return;
      
      setIsLoading(true);

      reports.actions.getDoorDetails(doorId, from, to)
        .then(data => {
          if (!data) return;

          setStats({
            door: data.door,
            unloads: data.stats,
            employees: data.employees,
            ...aggregateDoorDetailsStats(data.stats),
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [reports.actions, dateRange, params.id]);

  const showEmployeesModal = useCallback((item) => {
    if (item.employees && item.employees.length) {
      setEmployeesModal({
        ...employeesModal,
        show: true,
        employees: item.employees
      });
    }
  }, [employeesModal]);

  const hideEmployeesModal = useCallback(() => {
    setEmployeesModal({
      ...employeesModal,
      show: false,
    });
  }, [employeesModal]);

  return (
    <MainLayout>
      <UnloadDetailsPageHeader
        // title={<Skeleton loading={isLoading} rows={1} active title={{ width: '140px' }} paragraph={false}>{stats.door && stats.door.name}</Skeleton>}
        title={stats.door ? stats.door.name : `Door ${params.id}`}
        backIcon={
          <Button shape="circle" size="large" icon={{ name: "arrow-left", color: colors.grey.lightDark, size: 18 }} />
        }
        tags={
          stats.door ? <StateTag door={stats.door} /> : null
        }
        extra={
          <UnloadFilter defaultValue={filterEntity} onChange={setFilterEntity} style={{ minWidth: 'calc(50vw - 76px)' }} />
        }
        onBack={() => {
          history.goBack();
        }}
      >
        {/* <Typography.Text style={{ fontSize: '12px', lineHeight: '20px' }}>
          Unload Speed: Slow
        </Typography.Text> */}
      </UnloadDetailsPageHeader>

      {filterEntity === 'unloads' ? (
        <Row justify="center" style={{ padding: '20px 24px 28px 24px', marginLeft: '12px' }}>
          <RadioGroup
            defaultValue={filterLevel}
            options={UNLOAD_FILTER_LEVELS}
            optionType="button"
            buttonStyle="solid"
            onChange={e => setFilterLevel(e.target.value)}
          />
        </Row>
      ) : null}

      <Row style={{ padding: `${filterEntity === 'unloads' ? '12px' : '20px'} 24px` }}>
        <FilterBar
          title={UNLOAD_REALTION_TITLE[filterEntity](filterLevel)}
          titleStyles={{ fontSize: '20px', lineHeight: '28px' }}
          size="large"
        >
          <FilterBar.Slot align="left">
            <DatePicker.RangePicker
              picker="date"
              defaultValue={[dateRange.start, dateRange.end]}
              onChange={(dates) => {
                if (dates) {
                  setDateRange({ start: dates[0], end: dates[1] });
                }
              }}
            />
          </FilterBar.Slot>
        </FilterBar>
      </Row>

      <Row style={{ padding: '0 24px' }}>
        <Col span={24}>
          <ViewSwitcher currentKey={filterEntity}>
            <ViewSwitcher.View viewKey="unloads">

              <ViewSwitcher currentKey={filterLevel}>
                <ViewSwitcher.View viewKey="details">
                  <Table columns={unloadDetailsColumns} dataSource={stats.unloads} rowKey='container_code' loading={isLoading} />
                </ViewSwitcher.View>
                <ViewSwitcher.View viewKey="stats">
                  <Divider style={{ margin: 0, padding: '4px 0' }} />
                  <Row style={{ paddingTop: '12px', paddingBottom: '30px' }}>
                    <Col span={24}>
                      <Grid gutter={24} columns={2} style={{ padding: '8px 0 16px' }}>
                        <StatsCard
                          title="Boxes Unloaded"
                          titleStyle={{ fontSize: '12px', lineHeight: '20px' }}
                          value={stats.totalBoxes}
                          titleAlign={['center', 'bottom']}
                        />
                        <StatsCard
                          title="Containers Unloaded"
                          titleStyle={{ fontSize: '12px', lineHeight: '20px' }}
                          value={stats.totalContainers}
                          titleAlign={['center', 'bottom']}
                        />
                      </Grid>
                    </Col>
                  </Row>
                </ViewSwitcher.View>
              </ViewSwitcher>

            </ViewSwitcher.View>

            <ViewSwitcher.View viewKey="employees">
              <Table columns={EMPLOYEES_DETAILS_COLUMNS} dataSource={stats.employees} style={{ height: '100%' }} rowKey="employee_id" loading={isLoading} />
            </ViewSwitcher.View>
          </ViewSwitcher>
        </Col>
      </Row>

      <Modal
        title={'Employees'}
        visible={employeesModal.show}
        onCancel={hideEmployeesModal}
        footer={
          <Button onClick={hideEmployeesModal}>Close</Button>
        }
        centered={true}
        keyboard={true}
      >
        {employeesModal.employees.map((employee, key) =>
          <Link to={`/individuals/${employee.employee_id}`} key={key} style={{ color: colors.primary, display: 'block', paddingBottom: '8px', }}>
            {employee.full_name}
          </Link>
        )}
      </Modal>

    </MainLayout>
  );
};

export default UnloadsDoorDetails;