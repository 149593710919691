import useModel from 'hooks/useModel';
import createApi from 'services/api';
import { crudActions, crudReducer } from 'state/crudHelper';

const api = createApi({
  baseUrl: 'admin/doors',
  headers: {
    'Content-Type': 'application/json'
  },
});

const initialState = {
  isLoading: false,
  dataError: null,
  itemError: null,
  data: [],
  activeItem: null,
};

const reducer = (state = initialState, action, attributes) => {
  switch (action.type) {
    default:
      return crudReducer(state, action, attributes);
  }
};

export default useModel({
  reducer,
  actions: {
    ...crudActions(api),
  }
}, {
  idField: 'door_id',
  fields: [
    {
      title: 'Door ID',
      dataIndex: 'name',
    },
    {
      title: 'Location Name',
      dataIndex: 'location_id',
    },
    // {
    //   title: 'Latitude',
    //   dataIndex: 'lat',
    // },
    // {
    //   title: 'Longitude',
    //   dataIndex: 'lon',
    // },
    {
      title: 'Status',
      dataIndex: 'is_active',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      tableView: false,
    },
  ]
});
