import React from 'react';
import styled from 'styled-components';
import { Row, Col, Card, Typography } from 'antd';
import Grid from 'components/Grid';

import { formatDate, getHours, getMinutes, getSeconds } from 'helpers/date';

import ContainerSvg from '../images/container.svg';

const { Text } = Typography;

const StyledCard = styled(Card)`
  background-color: #FFFFFF;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  & .ant-card-body {
    display: flex;
    flex-direction: column;
  }
`;

const StyledInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & .name {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #A4B0BA;
  }

  & .value {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: ${props => props.isEst ? '#A4B0BA' : '#232526'};
  }
`;

const InfoItem = ({ name, value, isEst, ...rest }) => (
  <StyledInfoItem isEst={isEst} {...rest}>
    <Text className="name">{name}:</Text>
    <Text className="value">{value ? (isEst ? `est. ${value}` : value) : '-'}</Text>
  </StyledInfoItem>
);

const ContainerInfoCard = ({ container, ...rest }) => {
  if (container === undefined || container === null) {
    container = {};
  }

  return (
    <StyledCard {...rest}>
      <Row>
        <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexBasis: '390px', marginLeft: '-20px' }}>
          <img alt="container vector" src={ContainerSvg} />
        </Col>
        <Col flex={1} style={{ padding: '12px 0' }}>
          <Grid gutter={[24, 24]} columns={2} colStyle={{ maxWidth: '270px' }}>
            <InfoItem name="Code" value={container.code} />
            <InfoItem name="Docked" {...getDockedField(container)} />
            <InfoItem name="Container type" value={container.content_class} />
            <InfoItem name="Unload" {...getUnloadDurationField(container)} />
            <InfoItem name="Door" value={container.door_name} />
            <InfoItem name="Undocked" {...getUndockedField(container)} />
            <InfoItem name="Boxes Unloaded" value={container.unloaded_boxes} />
            <InfoItem name="Idle time during last unload" {...getIdleTimeField(container)} />
            <InfoItem name="Total labor expended" {...getTotalLaborTimeField(container)} />
          </Grid>
        </Col>
      </Row>
    </StyledCard>
  );
};

const getDockedField = (container) => {
  const { docked_at } = container;
  const field = { value: null };

  if (docked_at) {
    field.value = formatDate(docked_at * 1000, 'MMM dd, yyyy \'at\' HH:mm');
  }

  return field;
};

const getUnloadDurationField = (container) => {
  let { unload_duration, estimated_start, estimated_end } = container;
  const field = { value: null, isEst: false };

  if (unload_duration) {
    field.value = `${getHours(unload_duration)}h ${getMinutes(unload_duration)}m`;
  } else if (estimated_start && estimated_end) {
    field.isEst = true;
    unload_duration = (estimated_end - estimated_start) * 1000;
    field.value = `${getHours(unload_duration)}h ${getMinutes(unload_duration)}m`;
  }

  return field;
};

const getUndockedField = (container) => {
  const { undocked_at, estimated_undock } = container;
  const field = { value: null, isEst: false };

  if (undocked_at) {
    field.value = formatDate(undocked_at * 1000, 'MMM dd, yyyy \'at\' HH:mm');
  } else if (estimated_undock) {
    field.isEst = true;
    field.value = formatDate(estimated_undock * 1000, 'MMM dd, yyyy \'at\' HH:mm')
  }

  return field;
};

const getIdleTimeField = (container) => {
  const { dock_start_iddle, dock_end_iddle } = container;
  const field = { value: null };

  if (dock_start_iddle !== undefined || dock_end_iddle !== undefined) {
    const idleTime = (dock_start_iddle || 0) + (dock_end_iddle || 0);
    let mins = getMinutes(idleTime);
    let secs = getSeconds(idleTime);

    if (mins > 0) mins = `${mins}m`;
    else mins = '';
    secs += 's';

    field.value = `${mins} ${secs}`;
  }

  return field;
};

const getTotalLaborTimeField = (container) => {
  const { tot_labor_man_mins } = container;
  const field = { value: null };

  if (tot_labor_man_mins) {
    let totTime = Math.round(tot_labor_man_mins * 60);

    let hrs = getHours(totTime);
    let mins = `${getMinutes(totTime)}m`;

    if (hrs > 0) hrs = `${hrs}h`
    else hrs = '';

    field.value = [hrs, mins].join(' ');
  }

  return field;
};

export default ContainerInfoCard;
