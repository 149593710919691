import React from 'react';
import { Typography, Tag, Tooltip } from 'antd';
import { formatDate, getHours, getMinutes, getSeconds } from 'helpers/date';

import colors from 'config/colors'
import Link from 'components/Link';

const { Text } = Typography;

export const STATUS_FILTER_OPTIONS = [
  {
    label: 'All Doors',
    value: 'all',
  },
  {
    label: 'Unloading',
    value: 'unloading',
  },
  {
    label: 'Stopped',
    value: 'stopped',
  },
  {
    label: 'Empty',
    value: 'empty',
  }
];

export const DOORS_TABLE_COLUMNS = [
  {
    title: 'Door',
    dataIndex: 'name',
    key: 'name',
    render: (doorName, { door_id }) => (
      <Link to={`/unloads/${door_id}`}>{doorName}</Link>
    )
  },
  {
    title: 'Container Code',
    dataIndex: 'container_code',
    key: 'container_code',
    render: (containerCode, { container_id }) => {
      if (!containerCode) return '-';
      return <Link to={`/containers/${container_id}`}>{containerCode}</Link>;
    },
  },
  {
    title: 'Status',
    key: 'door_state',
    dataIndex: 'door_state',
    align: 'center',
    render: (doorState) => (
      <Tag color={{ [DOOR_STATE.EMPTY]: 'default', [DOOR_STATE.STOPPED]: 'error' }[doorState] || 'success'} style={{ textTransform: 'capitalize' }}>
        {doorState.toLowerCase()}
      </Tag>
    ),
  },
  {
    title: 'Idle time',
    key: 'idle_time',
    align: 'right',
    render: ({ dock_start_iddle, dock_end_iddle }) => {
      const time = dock_start_iddle + dock_end_iddle;
      let mins = getMinutes(time);
      let secs = getSeconds(time);

      if (mins > 0) mins = `${mins}m`;
      else mins = '';
      secs += 's';

      return `${mins} ${secs}`;
    }
  },
  {
    title: 'Docked at time',
    key: 'docked_at',
    dataIndex: 'docked_at',
    align: 'right',
    render: dockedAt => formatDate(dockedAt * 1000, 'p'),
  },
  {
    title: 'Progress %',
    key: 'unloaded_percent',
    dataIndex: 'unloaded_percent',
    align: 'center',
    render: (unloaded_percent, door) => door.door_state !== DOOR_STATE.EMPTY ? (
      <Tag color="processing">~{(unloaded_percent * 100).toFixed(0)}%</Tag>
    ): '',
  },
  {
    title: 'Unloading start',
    key: 'started_at',
    dataIndex: 'started_at',
    align: 'right',
    render: startedAt => formatDate(startedAt * 1000, 'p'),
  },
  {
    title: 'Duration',
    dataIndex: 'unload_duration',
    key: 'unload_duration',
    align: 'center',
    render: (unload_duration) => {
      if (typeof unload_duration !== 'number') return '';
      return `${getHours(unload_duration)}h ${getMinutes(unload_duration)}m`;
    }
  },
  {
    title: 'Current Employees',
    dataIndex: 'employees',
    key: 'employees',
    align: 'right',
    render: employees => {
      if (!employees || !employees.length) {
        return <Text style={{ color: colors.primary }}>0</Text>
      }

      return (
        <Tooltip title={employees.map((e, i) =>
          <span key={i}>{e.full_name}<br/></span>
        )} trigger="hover" placement="topRight">
          <Text style={{ color: colors.primary }}>{employees.length}</Text>
        </Tooltip>
      );
    },
  },
];

export const DOOR_STATE = {
  UNLOADING: 'UNLOADING',
  EMPTY: 'EMPTY',
  STOPPED: 'STOPPED',
};
