import UsersPage from './Users.page';
import LocationsPage from './Locations.page';
import DoorsPage from './Doors.page';
import EmployeesPage from './Employees.page';

UsersPage.path = '/users';
LocationsPage.path = '/locations';
DoorsPage.path = '/doors';
EmployeesPage.path = '/employees';

export default {
  pages: {
    UsersPage,
    LocationsPage,
    DoorsPage,
    EmployeesPage,
  }
};
