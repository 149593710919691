export const mapLocationsToOptions = (locations = []) => {
  return locations.filter(l => l.is_active === 1)
    .map(l => ({
      label: l.location_name,
      value: l.location_id,
    }));
};

export const mapDoorsToOptions = (doors = []) => {
  return doors.filter(d => d.is_Active === 1)
    .map(d => ({
      label: d.name,
      value: d.door_id,
    }));
};