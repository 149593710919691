import { IS_LOADING, STATS_DOORS, STATS_EMPLOYESS, STATS_OK, STATS_FAILED } from './constants';

export const initialState = {
  isLoading: false,
  error: null,
  data: {
    [STATS_DOORS]: [],
    [STATS_EMPLOYESS]: [],
  },
};

export const reducer = (state = initialState, action) => {
  switch(action.type) {
    case STATS_OK:
      return { ...state, data: { ...state.data, ...action.data }, error: null };

    case STATS_FAILED:
      return { ...state, error: action.data };

    case IS_LOADING:
      return { ...state, isLoading: action.data };

    default:
      return state;
  }
};
