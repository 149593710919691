import { useState, useCallback } from 'react';
import { Form } from 'antd';

const useForm = ({ initialMode = FormMode.reset, onSubmit } = {}) => {
  const [form] = Form.useForm();
  const [mode, setMode] = useState(initialMode);

  const create = useCallback(() => {
    setMode(FormMode.create);
  }, []);

  const edit = useCallback((values) => {
    form.setFieldsValue(values);
    setMode(FormMode.edit);
  }, [form]);

  const reset = useCallback(() => {
    form.resetFields();
    setMode(FormMode.reset);
  }, [form]);

  return [{ ...form, mode, create, edit, reset, onSubmit }];
};

export const FormMode = {
  create: 'create',
  edit: 'edit',
  reset: 'reset',
};

export default useForm;
