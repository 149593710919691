import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Space, Typography, DatePicker, Table, Tag, Col } from 'antd';

import colors from 'config/colors';
import useReports from 'state/reports';

import MainLayout from 'layouts/Main.layout';
import PageHeader from 'components/PageHeader';
import Grid from 'components/Grid';
import StatsCard from 'components/StatsCard';
import PeriodFilter from 'components/PeriodFilter';
import Button from 'components/Button';

import { getHours, getMinutes, toSeconds } from 'helpers/date';
import { INDIVIDUAL_STATS_PATH } from './index';
import { TOP_TEAMMATES_COLUMNS, TOP_CONTAINER_TYPES_COLUMNS, WORKING_TIMES_COLUMNS } from './constants';

const periodToDatePicker = {
  daily: 'date',
  weekly: 'week',
  monthly: 'month',
  yearly: 'year',
};

const IndividualDetails = () => {
  const reports = useReports();
  const [period, setPeriod] = useState('daily');
  const params = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({
    individual: null,
    worksBestWith: [],
    containerTypes: [],
    workingTimes: [],
    timeWorked: 0, boxesUnloaded: 0
  });
  const [datePicker, setDatePicker] = useState({ mode: 'date', current: moment(), });

  useEffect(() => {
    const id = parseInt(params.id);
    if (isNaN(id)) {
      history.replace(INDIVIDUAL_STATS_PATH);
    }

  }, [params, history]);

  useEffect(() => {
    if (params.id && datePicker.current) {
      const employeeId = parseInt(params.id, 10);
      if (isNaN(employeeId)) return;

      let start = datePicker.current.clone();
      let end = datePicker.current.clone();

      if (period === 'daily') {
        end = end.endOf('day');
      } else if (period === 'weekly') {
        end = end.endOf('week');
      } else if (period === 'monthly') {
        end = end.endOf('month');
      } else if (period === 'yearly') {
        end = end.endOf('year');
      }

      const from = toSeconds(new Date(Date.UTC(start.year(), start.month(), start.date())).getTime());
      const to = toSeconds(new Date(Date.UTC(end.year(), end.month(), end.date())).getTime());

      setIsLoading(true);

      reports.actions.getEmployeeDetails(employeeId, from, to)
        .then(data => {
          if (!data) return;
          const { full_name, status, boxes_unloaded, hours_worked } = data.summary;

          setStats({
            individual: {
              name: full_name,
              status,
            },
            worksBestWith: data.works_best_with,
            containerTypes: data.container_types,
            workingTimes: data.working_times,
            timeWorked: Math.round(hours_worked * 3600),
            boxesUnloaded: boxes_unloaded,
          });

        })
        .finally(() => {
          setIsLoading(false);
        });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports.actions, datePicker, params.id]);

  useEffect(() => {
    const mode = periodToDatePicker[period];
    let current = null;
    if (period === 'daily') {
      current = moment().startOf('day');
    } else if (period === 'weekly') {
      current = moment().startOf('week');
    } else if (period === 'monthly') {
      current = moment().startOf('month');
    } else if (period === 'yearly') {
      current = moment().startOf('year');
    }

    setDatePicker({ mode, current });
  }, [period]);

  return (
    <MainLayout>
      <PageHeader
        title={stats.individual ? stats.individual.name : `Individual ${params.id}`}
        tags={
          stats.individual && !!stats.individual.status ? <Tag color="success">Working</Tag> : null
        }
        backIcon={
          <Button shape="circle" size="large" icon={{ name: "arrow-left", color: colors.grey.lightDark, size: 18 }} />
        }
        extra={
          <PeriodFilter defaultActiveKey={period} onChange={setPeriod} style={{ minWidth: 'calc(64vw - 250px)' }} />
        }
        onBack={() => {
          history.goBack();
        }}
      />

      <Row style={{ padding: '0 24px 12px 24px' }}>
        <Space size="middle">
          <Typography.Text style={{ fontWeight: 600, fontSize: '20px', lineHeight: '28px', color: colors.grey.veryDark, }}>
            Stats
          </Typography.Text>
          <DatePicker
            mode={datePicker.mode}
            picker={datePicker.mode}
            value={datePicker.current}
            onChange={date => setDatePicker({ ...datePicker, current: date })}
            style={{ width: '260px' }}
          />
        </Space>
      </Row>

      <Grid gutter={24} columns={2} style={{ padding: '8px 24px 16px' }}>
        <StatsCard
          title="Worked this period of time"
          value={`${getHours(stats.timeWorked, true)}h ${getMinutes(stats.timeWorked, true)}m`}
          titleAlign={['center', 'bottom']}
        />
        <StatsCard
          title="Boxes unloads this period of time"
          value={stats.boxesUnloaded}
          titleAlign={['center', 'bottom']}
        />
      </Grid>

      <Row style={{ padding: '12px 24px', width: '100%' }}>
        <Col span={24}>
          <Typography.Text style={{ fontWeight: 600, fontSize: '24px', lineHeight: '32px', color: colors.black, paddingBottom: '16px', width: '100%' }}>
            Works best with
          </Typography.Text>
          <Table pagination={false} columns={TOP_TEAMMATES_COLUMNS} dataSource={stats.worksBestWith} rowKey="employee_id" loading={isLoading} />
        </Col>
      </Row>

      <Row style={{ padding: '12px 24px', width: '100%' }}>
        <Col span={24}>
          <Typography.Text style={{ fontWeight: 600, fontSize: '24px', lineHeight: '32px', color: colors.black, paddingBottom: '16px', width: '100%' }}>
            Container type
          </Typography.Text>
          <Table pagination={false} columns={TOP_CONTAINER_TYPES_COLUMNS} dataSource={stats.containerTypes} rowKey="container_class" loading={isLoading} />
        </Col>
      </Row>

      <Row style={{ padding: '12px 24px', width: '100%' }}>
        <Col span={24}>
          <Typography.Text style={{ fontWeight: 600, fontSize: '24px', lineHeight: '32px', color: colors.black, paddingBottom: '16px', width: '100%' }}>
            Working Times
          </Typography.Text>
          <Table columns={WORKING_TIMES_COLUMNS} dataSource={stats.workingTimes} rowKey="process_id" loading={isLoading} />
        </Col>
      </Row>

    </MainLayout>
  );
};


export default IndividualDetails;
