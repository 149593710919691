import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Typography, message } from 'antd';

import useAuth from 'state/auth';

import PublicLayout from 'layouts/Public.layout';
import ForgotPasswordForm from './components/ForgotPasswordForm';

import { RESET_PASSWORD_PATH } from './index';

const ForgotPasswordWrapper = styled.div`
  max-width: 364px;
`;

const ForgotPasswordInfo = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;

  display: flex;
  align-items: center;
  text-align: center;
  color: #232526;

  margin: 24px 0;
`;

const ForgotPasswordPage = () => {
  const auth = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      message.error(error.message, 2);
    }
  }, [error]);

  const onSubmit = useCallback(({ username }) => {
    setError(null);
    setIsLoading(true);

    auth.actions.forgotPassword(username)
      .then(() => {
        history.push(RESET_PASSWORD_PATH);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [auth.actions, history]);

  return (
    <PublicLayout>
      <ForgotPasswordWrapper>
        <Typography.Title level={2} align="middle" style={{ lineHeight: '38px' }}>Forgot password</Typography.Title>
        <ForgotPasswordInfo>
          Please enter your email address associated with your account in order to reset your password
        </ForgotPasswordInfo>
        <ForgotPasswordForm onSubmit={onSubmit} isLoading={isLoading} />
      </ForgotPasswordWrapper>
    </PublicLayout>
  );
};

export default ForgotPasswordPage;
