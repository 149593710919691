export const USER_TABLE_COLUMNS = [
  {
    title: 'User ID',
    key: 'user_id',
    dataIndex: 'user_id',
  },
  {
    title: 'Full Name',
    key: 'full_name',
    dataIndex: 'full_name',
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
  },
  {
    title: 'Location',
    key: 'location_name',
    dataIndex: 'location_name',
    render: (locatioName, { location_id }) => locatioName || location_id.toString(),
  },
];

export const LOCATION_TABLE_COLUMNS = [
  {
    title: 'Location ID',
    key: 'location_id',
    dataIndex: 'location_id',
  },
  {
    title: 'Location Name',
    key: 'location_name',
    dataIndex: 'location_name',
  },
  {
    title: 'Latitude',
    key: 'lat',
    dataIndex: 'lat',
  },
  {
    title: 'Longitude',
    key: 'lon',
    dataIndex: 'lon',
  },
  {
    title: 'Timezone',
    key: 'timezone_offset',
    dataIndex: 'timezone_offset',
  },
];

export const DOOR_TABLE_COLUMNS = [
  {
    title: 'Door ID',
    key: 'door_id',
    dataIndex: 'door_id',
  },
  {
    title: 'Door Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Location Name',
    key: 'location_name',
    dataIndex: 'location_name',
    render: (locatioName, { location_id }) => locatioName || location_id.toString(),
  },
  {
    title: 'Notes',
    key: 'notes',
    dataIndex: 'notes',
    render: notes => notes || '-',
  },
]