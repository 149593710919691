import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, message } from 'antd';

import PublicLayout from 'layouts/Public.layout';
import useAuth from 'state/auth';

import LoginForm from './components/LoginForm';

const LoginWrapper = styled.div`
  max-width: 364px;
`;

const LoginPage = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);

  useEffect(() => {
    if (loginError) {
      message.error(loginError.message, 2);
    }
  }, [loginError]);

  const onSubmit = useCallback(({ username, password }) => {
    setLoginError(null);
    setIsLoading(true);
    
    auth.actions.login(username, password)
      .catch(err => {
        setLoginError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [auth.actions]);

  return (
    <PublicLayout>
      <LoginWrapper>
        <Typography.Title level={2} align="middle" style={{ lineHeight: '38px' }}>Login</Typography.Title>
        <LoginForm onSubmit={onSubmit} isLoading={isLoading} />
      </LoginWrapper>
    </PublicLayout>
  );
};

export default LoginPage;
