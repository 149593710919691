import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, Checkbox, Form } from 'antd';
import Icon from 'components/Icon';

const LoginForm = ({ onSubmit, isLoading }) => (
  <Form
    layout="vertical"
    initialValues={{ remember: false }}
    onFinish={onSubmit}
    style={{ width: '360px' }}
    hideRequiredMark={true}
  > 
    <Form.Item
        name="username"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Please enter your email',
          },
        ]}
      >
        <Input
          prefix={<Icon name="mail" size={16} />}
          placeholder="Enter your email"
          size="large"
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please enter your password',
          },
        ]}
      >
        <Input.Password
          prefix={<Icon name="lock" size={16} />}
          type="password"
          placeholder="Enter password"
          size="large"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        <Link to="/forgot-password" style={{ float: 'right' }}>
          Forgot password
        </Link>
      </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={isLoading} disabled={isLoading} size="large">
        Login
      </Button>
    </Form.Item>
  </Form>
);

export default LoginForm;
