import React from 'react';
import { Input, Row, Col, Form } from 'antd';

const LocationForm = ({ form }) => (
  <Form
    form={form}
    layout="vertical"
    hideRequiredMark={true}
    onFinish={form.onSubmit}
  >
    <Row gutter={[12]}>
      <Col span={10}>
        <Form.Item
          label="Location Name"
          name="location_name"
          rules={[
            {
              required: true,
              message: 'Location name is required',
            },
          ]}
        >
          <Input placeholder="Enter location name" />
        </Form.Item>
      </Col>
      
      <Col span={7}>
        <Form.Item
          label="Latitude"
          name="lat"
        >
          <Input placeholder="Latitude" />
        </Form.Item>
      </Col>

      <Col span={7}>
      <Form.Item
        label="Longtitude"
        name="lon"
      >
        <Input placeholder="Longtitude" />
      </Form.Item>
    </Col>
    </Row>

    <Row gutter={[12]}>
      <Col span={10}>
        <Form.Item
          label="Timezone"
          name="timezone_offset"
          rules={[
            {
              required: true,
              message: 'Timezone is required'
            }
          ]}
          >
          <Input placeholder="Timezone" />
        </Form.Item>
      </Col>
    </Row>

    <Form.Item
      label="Notes"
      name="notes"
      >
       <Input.TextArea rows={3} placeholder="Notes" />
    </Form.Item>
  </Form>
);

export default LocationForm;