import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import { Input } from 'antd';

const SearchInput = ({ value, onChange, debounce, ...props }) => {
  const [search, setSearch] = useState('');
  const [timeout] = useState(() => {
    const shouldDebounce = !!debounce;
    if (shouldDebounce) {
      if (typeof debounce === 'number') {
        return debounce;
      }

      if (typeof debounce === 'string') {
        const timeout = parseInt(debounce, 10);
        if (!isNaN(timeout)) return timeout;
      }

      if (typeof debounce === 'boolean') {
        return 300;
      }
    }
    return 0;
  });

  useEffect(() => {
    if (value !== undefined && search !== value) {
      setSearch(value);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (search === value) return;

    let timer = null;
    if (timeout !== 0 && typeof onChange === 'function') {
      timer = setTimeout(() => {
        onChange(search);
      }, timeout);
    }

    return () => {
      if (timer !== null)
        clearTimeout(timer);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout, search, onChange]);

  return (
    <Input
      placeholder="Search"
      prefix={<Icon name="search" size={16} color="#A4B0BA" />}
      {...props}
      value={search}
      onChange={e => setSearch(e.target.value)}
    />
  );
}

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  debounce: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

SearchInput.defaultProps = {
  debounce: true,
};

export default SearchInput;
