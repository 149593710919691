import React, { useState, useEffect, useCallback } from 'react';

import useFilters from 'hooks/useFilters';
import useForm, { FormMode } from 'hooks/useForm';
import useLocations from 'state/locations';

import AdminLayout from 'layouts/Admin.layout';
import FormModal from 'components/FormModal';
import Table from 'components/Table';
import Button from 'components/Button';
import Search from 'components/Search';
import LocationForm from './components/Location.form';
import { EditAction, DeleteAction } from './components/TableActions';

import { LOCATION_TABLE_COLUMNS } from './constants';

const modalTitle = {
  create: 'Add new location',
  edit: 'Update location',
};

const submitButtonText = {
  create: 'Add location',
  edit: 'Update location',
};

const useLocationsFilters = useFilters([
  'location_id',
  'location_name',
  'lat',
  'lon',
  'timezone_offset',
]);

const LocationsPage = () => {
  const locations = useLocations();
  const [currentItem, setCurrenItem] = useState(null);
  const [columns] = useState(() => {
    return LOCATION_TABLE_COLUMNS;
  });
  const filters = useLocationsFilters(locations.state.data);
  const [form] = useForm({
    onSubmit: (values) => {
      const save = form.mode === FormMode.create
        ? locations.actions.create({ ...values, is_active: 1 })
        : locations.actions.update({ ...currentItem, ...values });

      save.then(() => {
        form.reset();
        setCurrenItem(null);
      });
    }
  });

  useEffect(() => {
    locations.actions.getAll();
  }, [locations.actions]);

  const onAction = useCallback((actionType, item) => {
    switch (actionType) {
      case EditAction.actionType:
        setCurrenItem(item);
        form.edit(item);
      break

      case DeleteAction.actionType:
        locations.actions.remove({
          location_id: item.location_id,
        });
      break;

      default: // make eslint happy :)
    }
  }, [form, locations.actions]);
  
  return (  
    <AdminLayout
      title="Locations"
      icon="map-pin"
      extra={[
        <Search placeholder="Search location" value={filters.search} onChange={value => filters.setSearch(value)} />,
        <Button type="primary" icon="plus" onClick={form.create}>Add new location</Button>
      ]}
      formWidth="560px"
    >
      <FormModal
        title={`${modalTitle[form.mode]}`}
        form={form}
        submitText={`${submitButtonText[form.mode]}`}
        onClose={() => form.reset()}
      >
        <LocationForm form={form} />
      </FormModal>
      <Table
        loading={locations.state.isLoading}
        columns={columns}
        dataSource={filters.data}
        actions={[
          EditAction,
          DeleteAction,
        ]}
        actionsProps={{
          resource: 'location',
        }}
        onAction={onAction}
        rowKey="location_id"
      />
    </AdminLayout>
  );
};

export default LocationsPage;
