import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';

const { Option } = Select;

const BaseSelect = styled(Select)`
  // background-color: #FFFFFF;
  // box-sizing: border-box;
  
  // & .ant-select-selector {
  //   border: 1px solid #DAE0E6;
  //   border-radius: 4px;
  // }
`;

const SelectBox = ({ options, ...props }) => {
  return (
    <BaseSelect {...props}>
      {
        options.map((option, key) => <Option
          key={key}
          value={option.value}
          disabled={option.disabled ? true : false}
        >
          {option.label}
        </Option>)
      }
    </BaseSelect>
  );
}

export default SelectBox;