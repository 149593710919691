import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { Typography } from 'antd';

import colors from 'config/colors';

const { Text } = Typography;

const Link = ({ to, children, ...props }) => (
  <RouteLink to={to}>
    <Text style={{ color: colors.primary}} {...props}>
      {children}
    </Text>
  </RouteLink>
);

export default Link;