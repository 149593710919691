import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Space, DatePicker, Table, Typography } from 'antd';

import useFilters from 'hooks/useFilters';
import useReports from 'state/reports';
import useLocations from 'state/locations';

import colors from 'config/colors';
import MainLayout from 'layouts/Main.layout';
import PageHeader from 'components/PageHeader';
import ViewSwitcher from 'components/ViewSwitcher';
import Grid from 'components/Grid';
import StatsCard from 'components/StatsCard';
import RadioGroup from 'components/RadioGroup';
import FilterBar from 'components/FilterBar';
import Search from 'components/Search';
import Select from 'components/Select';
import PeriodFilter from 'components/PeriodFilter';

import { toSeconds } from 'helpers/date';
import { mapLocationsToOptions } from 'helpers/mapToOptions';
import { STATS_FILTER_LEVELS, INDIVIDUAL_TABLE_COLUMNS, TEAMS_TABLE_COLUMNS } from './constants';

const periodToDatePicker = {
  daily: 'date',
  weekly: 'week',
  monthly: 'month',
  yearly: 'year',
};

const useIndividualsFilters = useFilters([
  'full_name',
  'container_class',
]);

const useTeamsFilters = useFilters([
  'container_class'
]);

const IndividualsStats = () => {
  const [isLoading, setIsLoading] = useState(true);
  const reports = useReports();
  const locations = useLocations();
  const [stats, setStats] = useState({ individuals: [], teams: [], avgPerContainer: 0, total: 0 });
  const [locationSelect, setLocationSelect] = useState({ options: [], value: null });
  const [containerTypeSelect, setContainerTypeSelect] = useState({ options: [], value: null });
  const [period, setPeriod] = useState('daily');
  const [datePicker, setDatePicker] = useState({ mode: 'date', current: moment(), });
  const [statsLevel, setStatsLevel] = useState(STATS_FILTER_LEVELS[0].value);
  const individualsFilters = useIndividualsFilters(stats.individuals);
  const teamsFilters = useTeamsFilters(stats.teams);

  useEffect(() => {
    locations.actions.getAll();
  }, [locations.actions]);

  useEffect(() => {
    const locationOptions = mapLocationsToOptions(locations.state.data);
    setLocationSelect({
      options: locationOptions,
      value: locationOptions.length ? locationOptions[0].value : null,
    });
  }, [locations.state.data]);

  useEffect(() => {
    if (containerTypeSelect.value === 'all') {
      individualsFilters.setQuery({ container_class: null });
      teamsFilters.setQuery({ container_class: null });
    } else {
      if (typeof containerTypeSelect.value === 'string') {
        individualsFilters.setQuery({ container_class: containerTypeSelect.value });
        teamsFilters.setQuery({ container_class: containerTypeSelect.value });
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerTypeSelect]);

  useEffect(() => {
    const containerTypes = stats.individuals.map(individual => individual.container_class);
    const containerTypeOptions = Array.from(new Set(containerTypes)).map(containerClass => ({
      label: containerClass,
      value: containerClass,
    }));

    containerTypeOptions.unshift({
      label: 'All Container Types',
      value: 'all',
    });

    setContainerTypeSelect({
      options: containerTypeOptions,
      value: containerTypeOptions.length ? containerTypeOptions[0].value : null,
    });
  }, [stats.individuals]);

  useEffect(() => {
    const mode = periodToDatePicker[period];
    let current = null;
    if (period === 'daily') {
      current = moment().startOf('day');
    } else if (period === 'weekly') {
      current = moment().startOf('week');
    } else if (period === 'monthly') {
      current = moment().startOf('month');
    } else if (period === 'yearly') {
      current = moment().startOf('year');
    }

    setDatePicker({ mode, current });
  }, [period]);

  useEffect(() => {
    if (locationSelect.value !== null && datePicker.current) {
      setIsLoading(true);

      const start = datePicker.current.clone();
      let end = datePicker.current.clone();

      if (period === 'daily') {
        end = moment(start).endOf('day');
      } else if (period === 'weekly') {
        end = moment(start).endOf('week');
      } else if (period === 'monthly') {
        end = moment(start).endOf('month');
      } else if (period === 'yearly') {
        end = moment(start).endOf('year');
      }

      const from = toSeconds(new Date(Date.UTC(start.year(), start.month(), start.date())).getTime());
      const to = toSeconds(new Date(Date.UTC(end.year(), end.month(), end.date())).getTime());
      
      Promise.all([
        reports.actions.getEmployeesStats(locationSelect.value, from, to),
        reports.actions.getTeamsStats(locationSelect.value, from, to),
      ]).then(([data, teams]) => {
          if (!data) return;

          setStats({
            individuals: data.stats,
            teams: teams ? teams.stats : [],
            avgPerContainer: data.summary.avg_employees_per_container || 0,
            total: data.summary.tot_employees || 0,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports.actions, locationSelect.value, datePicker]);

  return (
    <MainLayout>
      <PageHeader
        title="Individuals Stats"
        extra={
          <PeriodFilter defaultActiveKey={period} onChange={setPeriod} style={{ minWidth: 'calc(64vw - 250px)' }} />
        }
        icon="users"
      />

      <Row style={{ padding: '12px 24px' }}>
        <Space size="large">
          <Select
            options={locationSelect.options}
            value={locationSelect.value}
            onChange={value => setLocationSelect({ ...locationSelect, value })}
            style={{ width: '260px' }}
            placeholder="Select Location"
          />
          <Select
            options={containerTypeSelect.options}
            value={containerTypeSelect.value}
            onChange={value => setContainerTypeSelect({ ...containerTypeSelect, value })}
            style={{ width: '260px' }}
            placeholder="Select Container Type"
          />
          <DatePicker
            mode={datePicker.mode}
            picker={datePicker.mode}
            value={datePicker.current}
            onChange={date => setDatePicker({ ...datePicker, current: date })}
            style={{ width: '260px' }}
          />
        </Space>
      </Row>

      <Grid gutter={24} columns={2} style={{ padding: '8px 24px 16px' }}>
        <StatsCard
          title="Total Individuals"
          value={stats.total}
          titleAlign={['center', 'bottom']}
        />
        <StatsCard
          title="Average Individuals / container"
          value={stats.avgPerContainer}
          titleAlign={['center', 'bottom']}
        />
      </Grid>

      <Row justify="center" style={{ padding: '12px 0' }}>
        <RadioGroup
          defaultValue={statsLevel}
          options={STATS_FILTER_LEVELS}
          optionType="button"
          buttonStyle="solid"
          onChange={e => setStatsLevel(e.target.value)}
        />
      </Row>

      <Row style={{ padding: '12px 24px', width: '100%' }}>
        <Col span={24}>
          <ViewSwitcher currentKey={statsLevel}>
            <ViewSwitcher.View viewKey={'individuals'}>
              <FilterBar
                title="Individuals"
                style={{ width: '100%', paddingBottom: '16px' }}
              >
                <FilterBar.Slot align="right">
                  <Search placeholder="Search individual" value={individualsFilters.search} onChange={value => individualsFilters.setSearch(value)} />
                </FilterBar.Slot>
              </FilterBar> 
              <Table columns={INDIVIDUAL_TABLE_COLUMNS} dataSource={individualsFilters.data} loading={isLoading} rowKey={'employee_id'} />
            </ViewSwitcher.View>
            <ViewSwitcher.View viewKey={'teams'}>
              <Row gutter={0}>
                <Typography.Text style={{ fontWeight: 600, fontSize: '24px', lineHeight: '32px', color: colors.black, paddingBottom: '16px', width: '100%' }}>
                  Best perfomed teams
                </Typography.Text>
              </Row>
              <Table columns={TEAMS_TABLE_COLUMNS} dataSource={teamsFilters.data} loading={isLoading} rowKey={'team_id'} />
            </ViewSwitcher.View>
          </ViewSwitcher>
        </Col>
      </Row>

    </MainLayout>
  );
};


export default IndividualsStats;