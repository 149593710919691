import React from 'react';

import MainLayout from 'layouts/Main.layout';

const SettingsPage = () => (
  <MainLayout>
    <h1>Settings Content</h1>
  </MainLayout>
);

SettingsPage.path = '/settings';
export default SettingsPage;
