import IndividualsPage from './Individuals.page';
import IndividualsStatsPage from './Stats.page';
import IndividualDetails from './Details.page';

export const INDIVIDUAL_STATS_PATH = '/individuals';

IndividualsPage.path = '/individuals/active';
IndividualsStatsPage.path = INDIVIDUAL_STATS_PATH;
IndividualDetails.path = '/individuals/:id';

export default {
  pages: {
    IndividualsPage,
    IndividualsStatsPage,
    IndividualDetails,
  }
};
